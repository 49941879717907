import React, { memo, useState } from 'react';
import { View } from 'react-native';

import {
  Item,
  ItemBotao,
  ItemBotaoIconeRemover,
  ItemDescricao,
  ItemInfo,
  ItemNome,
  Secao,
  SecaoAviso,
  SecaoBotao,
  SecaoBotaoContainer,
  SecaoBotaoIconeAdd,
  SecaoConteudo,
  SecaoDescricao,
  SecaoDescricaoDestaque,
  SecaoTitulo,
  SecaoTituloContainer,
  SecaoTituloContainerTamanho,
  SecaoVazia,
  SecaoVaziaTexto,
  ViewFlex,
} from '../globalStyles';
import EscolhaSabores from './EscolhaSabores';

const Sabores = ({
  qtdMaxSaboresExtras,
  sabores,
  idSaborPadrao,
  selecionarSabor,
  removerSabor,
  precoTamanhoPizza,
}) => {
  const [visivel, setVisivel] = useState(false);

  return (
    <Secao>
      <SecaoTituloContainerTamanho>
          <ViewFlex>
            <SecaoTitulo>Sabores</SecaoTitulo>
            <SecaoDescricao>
              Escolha mais{' '}
              <SecaoDescricaoDestaque>
                {qtdMaxSaboresExtras}
              </SecaoDescricaoDestaque>{' '}
              {qtdMaxSaboresExtras === 1 ? 'sabor' : 'sabores'}
            </SecaoDescricao>
            <SecaoAviso>Opcional</SecaoAviso>
          </ViewFlex>
          <SecaoBotao
            desabilitado={sabores.length === qtdMaxSaboresExtras}
            onPress={() => setVisivel(true)}
          >
            <SecaoBotaoIconeAdd />
          </SecaoBotao>
      </SecaoTituloContainerTamanho>
      <SecaoConteudo>
        {sabores.length ? (
          <>
            {sabores.map((sabor, index) => (
              <Item
                key={`${sabor.id}-Produto-SaboresSelecionados`}
                divisor={!!index}
              >
                <ItemInfo>
                  <ItemNome>{sabor.descricao}</ItemNome>
                  <ItemDescricao>
                    {sabor[`${precoTamanhoPizza}Formatado`]}
                  </ItemDescricao>
                </ItemInfo>
                <ItemBotao onPress={() => removerSabor(index)}>
                  <ItemBotaoIconeRemover />
                </ItemBotao>
              </Item>
            ))}
          </>
        ) : (
          <SecaoVazia>
            <SecaoVaziaTexto>Selecione mais sabores</SecaoVaziaTexto>
          </SecaoVazia>
        )}
      </SecaoConteudo>
      <EscolhaSabores
        visivel={visivel}
        onClose={() => setVisivel(false)}
        idSaborPadrao={idSaborPadrao}
        sabores={sabores}
        selecionarSabor={selecionarSabor}
      />
    </Secao>
  );
};

export default memo(Sabores);
