import { MaterialIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';

import theme from '../../../theme/index';

export const Container = styled.View`
  margin-bottom: 30px;
  background-color: ${theme.cores.superficie};
  padding: 16px;
  border-top-width: 1px;
  border-top-color: ${theme.cores.cinzas.c1};
`;

export const ObservacaoTituloContainer = styled.View`
  flex-direction: row;
  align-items: center;

  margin-bottom: 12px;
`;

export const ObservacaoIcone = styled(MaterialIcons).attrs(() => ({
  color: theme.cores.cinzas.c3,
  size: 20,
  name: 'message',
}))``;

export const ObservacaoTitulo = styled.Text`
  font-size: ${theme.fontes.normal};
  line-height: ${theme.fontes.normal};
  font-weight: bold;
  color: ${theme.cores.cinzas.c4};
  margin-left: 8px;
`;

export const ObservacaoInput = styled.TextInput.attrs(() => ({
  multiline: true,
  numberOfLines: 3,
  placeholderTextColor: theme.cores.cinzas.c2,
}))`
  border-radius: 4px;
  padding: 8px 12px;
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.normal};
  border: 2px solid ${theme.cores.cinzas.c1};
`;
