import React, { useEffect, useState } from 'react';
import { useSessao } from '../../hooks/sessao';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardAvoidingView, Platform, TouchableOpacity, View } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import erroImg from '../../assets/embarrassed.png';
import Carregando from '../../components/Carregando';
import Container from '../../components/Container';
import Erro from '../../components/Erro';
import Input from '../../components/Input';
import STATUS from '../../config/status';
import { useTema } from '../../hooks/tema';
import { listarGrupos } from '../../services/grupos';
import {
  ContainerInput,
  GruposTitulo,
  Grupos,
  Grupo,
  GrupoFundo,
  GrupoImagem,
  GrupoInvisivel,
  GrupoNome,
  Icone
} from './styles';

const Buscar = () => {
  const { tema } = useTema();
  const [grupos, setGrupos] = useState([]);
  const [status, setStatus] = useState(STATUS.CARREGANDO);
  const { control, handleSubmit } = useForm();
  const navigation = useNavigation();
  const focado = useIsFocused();
  const { empresa } = useSessao();

  const buscarDados = async () => {
    try {
      setStatus(STATUS.CARREGANDO);
      const gruposDados = await listarGrupos({});
      setGrupos(gruposDados);

      setStatus(STATUS.SUCESSO);
    } catch (error) {
      setStatus(STATUS.ERRO);
    }
  };

  useEffect(() => {
    control.setValue('produtoNome', '');
  }, [focado, control]);

  useEffect(() => {
    buscarDados();
  }, []);

  const buscarCompleto = ({ grupoId, grupoNome, produtoNome }) => {
    navigation.navigate('BuscarCompleto', {
      grupoId,
      grupoNome,
      produtoNome,
    });
  };

  return (
    <KeyboardAvoidingView
      style={{
        flex: 1,
      }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <Container corStatusBar={tema.cores.primaria}>
        {status === STATUS.ERRO && (
          <Erro
            imagem={erroImg}
            mensagem="Desculpe! Ocorreu um erro"
            recarregar={buscarDados}
          />
        )}
        {status === STATUS.CARREGANDO && <Carregando />}
        {status === STATUS.SUCESSO && (
          <>
            <ContainerInput
              style={{ backgroundColor: `${empresa.configuracoes.tema}` }}
            >
              <Controller
                control={control}
                name="produtoNome"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Input
                      placeholder="Buscar produto"
                      returnKeyType="search"
                      onBlur={onBlur}
                      onChangeText={produtoNome => onChange(produtoNome)}
                      value={value}
                      onSubmitEditing={handleSubmit(buscarCompleto)}
                      style={{ width: '86%' }}
                    />
                    <TouchableOpacity onPress={handleSubmit(buscarCompleto)}><Icone name="search" style={{ width: '14%' }} resizeMode="contain" /></TouchableOpacity>
                  </View>

                )}
              />
            </ContainerInput>
            <GruposTitulo>Categorias</GruposTitulo>
            <Grupos
              ListFooterComponent={<View style={{ marginBottom: '40%' }} />}
              data={grupos}
              numColumns={2}
              renderItem={({ item, index }) => (
                <>
                  <Grupo
                    key={`${item.id.toString()}-Grupo-Buscar`}
                    onPress={() =>
                      buscarCompleto({
                        grupoId: item.id,
                        grupoNome: item.descricao,
                      })
                    }
                  >
                    <GrupoImagem
                      source={{ uri: item.imagemURL || null }}
                      resizeMode="cover"
                      borderRadius={4}
                    >
                      <GrupoFundo>
                        <GrupoNome>{item.descricao}</GrupoNome>
                      </GrupoFundo>
                    </GrupoImagem>
                  </Grupo>
                  {grupos.length % 2 !== 0 && index + 1 === grupos.length && (
                    <GrupoInvisivel />
                  )}
                </>
              )}
            />
          </>
        )}
      </Container>
    </KeyboardAvoidingView>
  );
};

export default Buscar;
