import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Animated, { Extrapolate } from 'react-native-reanimated';

import { useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';

import Carregando from '../../../components/Carregando';
import STATUS from '../../../config/status';
import Adicionais from '../Adicionais';
import { ALTURA_IMAGEM_PRODUTO, ALTURA_NAV } from '../config';
import { Container } from '../globalStyles';
import { useProduto } from '../hooks/produto';
import Montagens from '../Montagens';
import Observacao from '../Observacao';
import ProdutoInformacoes from '../ProdutoInformacoes';
import Rodape from '../Rodape';
import Sabores from '../Sabores';
import Tamanhos from '../Tamanhos';
import {
  Nav,
  NavProdutoNome,
  NavBotaoVoltar,
  NavBotaoVoltarIcone,
  ContainerInterno,
  ContainerRadio,
  RadioDescription,
  RadioButtonForTravel,
} from './styles';

const ProdutoContainer = () => {
  const {
    produto,
    saboresSelecionados,
    aumentarQuantidade,
    diminuirQuantidade,
    status,
    aumentarAdicional,
    diminuirAdicional,
    aumentarAdicionalDesabilitado,
    tamanhoSelecionado,
    selecionarTamanho,
    totalFormatado,
    adicionarDesabilitado,
    quantidadeSabores,
    selecionarSabor,
    removerSabor,
    precoTamanhoPizza,
    adicionarItemMontagem,
    removerItemMontagem,
    itensMontagens,
    enviarProduto,
  } = useProduto();
  const navigation = useNavigation();
  const [forTravel, setForTravel] = useState(false);
  const { control, handleSubmit } = useForm();

  const voltar = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onSubmit = useCallback(
    ({ observacao }) => {
      const obs = (forTravel ? `PARA VIAGEM ${observacao ? '-' : ''} ` : '') + observacao;
      enviarProduto(obs);
      navigation.goBack();
    },
    [enviarProduto, navigation, forTravel],
  );

  const onSubmitDelayed = useMemo(
    () =>
      debounce(
        data => {
          onSubmit(data);
        },
        1000,
        { leading: true, trailing: false },
      ),
    [onSubmit],
  );

  const scrollY = useRef(new Animated.Value(0)).current;

  const navY = Animated.interpolateNode(scrollY, {
    inputRange: [0, ALTURA_IMAGEM_PRODUTO - ALTURA_NAV],
    outputRange: [-ALTURA_NAV, 0],
    extrapolate: Extrapolate.CLAMP,
  });

  const navOpacidade = Animated.interpolateNode(scrollY, {
    inputRange: [
      ALTURA_IMAGEM_PRODUTO * 0.55,
      ALTURA_IMAGEM_PRODUTO - ALTURA_NAV,
    ],
    outputRange: [0, 1],
    extrapolate: Extrapolate.CLAMP,
  });

  return (
    <Container>
      {status === STATUS.CARREGANDO && <Carregando />}
      {status === STATUS.SUCESSO && (
        <>
          <Nav
            altura={ALTURA_NAV}
            style={{
              opacity: navOpacidade,
              translateY: navY,
            }}
          >
            <NavProdutoNome>{produto.descricao}</NavProdutoNome>
          </Nav>
          <NavBotaoVoltar alturaNav={ALTURA_NAV} onPress={voltar}>
            <NavBotaoVoltarIcone />
          </NavBotaoVoltar>
          <ContainerInterno
            scrollEventThrottle={1}
            onScroll={Animated.event([
              {
                nativeEvent: {
                  contentOffset: {
                    y: scrollY,
                  },
                },
              },
            ])}
          >
            <ProdutoInformacoes produto={produto} />

            {!!produto.tamanhos.length && (
              <Tamanhos
                tamanhos={produto.tamanhos}
                tamanhoSelecionado={tamanhoSelecionado}
                selecionarTamanho={selecionarTamanho}
              />
            )}

            {produto.tipoProduto === 'PIZZA' && quantidadeSabores > 1 && (
              <Sabores
                sabores={saboresSelecionados}
                qtdMaxSaboresExtras={quantidadeSabores - 1}
                idSaborPadrao={produto.id}
                selecionarSabor={selecionarSabor}
                removerSabor={removerSabor}
                precoTamanhoPizza={precoTamanhoPizza}
              />
            )}

            {!!produto.montagens.length && produto.montagemPrato && (
              <Montagens
                tamanhoSelecionado={tamanhoSelecionado}
                montagens={produto.montagens}
                adicionarItemMontagem={adicionarItemMontagem}
                removerItemMontagem={removerItemMontagem}
                itensMontagens={itensMontagens}
              />
            )}

            {!!produto.adicionais.length &&
              produto.qtdMaxAdicional !== 0 &&
              produto.pedirAdicional && (
                <Adicionais
                  adicionais={produto.adicionais}
                  aumentarAdicional={aumentarAdicional}
                  diminuirAdicional={diminuirAdicional}
                  aumentarAdicionalDesabilitado={aumentarAdicionalDesabilitado}
                  qtdMaxAdicionais={produto.qtdMaxAdicional}
                />
              )}

            <ContainerRadio
              onPress={() => setForTravel(!forTravel)}
            >
              <RadioDescription>Para Viagem ?</RadioDescription>
              <RadioButtonForTravel
                onPress={() => setForTravel(!forTravel)}
                value={forTravel}
                status={forTravel ? 'checked' : 'unchecked'}
              />
            </ContainerRadio>
            <Observacao control={control} />

          </ContainerInterno>
          <Rodape
            adicionarProduto={handleSubmit(onSubmitDelayed)}
            aumentarQuantidade={aumentarQuantidade}
            diminuirQuantidade={diminuirQuantidade}
            totalFormatado={totalFormatado}
            adicionarDesabilitado={adicionarDesabilitado}
          />
        </>
      )}
    </Container>
  );
};

export default ProdutoContainer;
