import { Modal, Platform } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const Container = styled.View`
  ${Platform.select({
    web: css``,
    android: css`
      flex: 1;
    `,
    ios: css`
      flex: 1;
    `,
  })}
`;

export const ModalContainer = styled(Modal).attrs(() => ({
  animationType: 'slide',
  transparent: true,
}))``;

export const ModalBackdrop = styled.View`
  ${Platform.select({
    web: css`
      flex: 1;
      background-color: rgba(0, 0, 0, 0.7);
    `,
    android: css`
      flex: 1;
      background-color: rgba(0, 0, 0, 0.7);
    `,
    ios: css`
      flex: 1;
      background-color: rgba(0, 0, 0, 0.7);
    `,
  })}
`;

export const Conteudo = styled.View`
  ${Platform.select({
    web: css`
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: ${theme.cores.superficie};
      padding: 16px;
      height: 90%;
      margin-top: auto;

      position: relative;
    `,
    android: css`
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: ${theme.cores.superficie};
      padding: 16px;
      height: 90%;
      margin-top: auto;

      position: relative;
    `,
    ios: css`
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: ${theme.cores.superficie};
      padding: 16px;
      height: 90%;
      margin-top: auto;

      position: relative;
    `,
  })}
`;

export const Titulo = styled.Text`
  font-size: ${theme.fontes.grande};
  font-weight: bold;
  color: ${theme.cores.cinzas.c4};
  text-align: center;
  margin-bottom: 16px;
`;

export const BotaoFechar = styled.TouchableOpacity`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 10000;
`;

export const BotaoFecharIcone = styled(MaterialIcons).attrs(() => ({
  name: 'close',
  size: 25,
  color: theme.cores.cinzas.c3,
}))``;
