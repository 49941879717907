import React, { memo } from 'react';
import { View } from 'react-native';
import { useSessao } from '../../hooks/sessao';

import {
  Linha,
  Produto,
  ProdutoInfo,
  ProdutoDescricao,
  ProdutoPreco,
  ProdutoQuantidade,
  ProdutoBotaoRemover,
  ProdutoBotaoRemoverIcone,
  ProdutoSubItemTitulo,
  ProdutoSubItem,
  ProdutoTotalTexto,
  ProdutoTotalValor,
  ProdutoObservacao,
} from './styles';

const ProdutoDetalhes = ({ item, remover }) => {
  const { empresa } = useSessao();
  return (
    <Produto>
      <Linha style={{ justifyContent: 'flex-start' }}>
        <ProdutoInfo>
          <ProdutoDescricao>{item.descricao}</ProdutoDescricao>
          <ProdutoPreco>{item.precoFormatado} </ProdutoPreco>
          <ProdutoQuantidade>Quantidade {item.quantidade}</ProdutoQuantidade>
          {item.observacao && <ProdutoObservacao>Obs.: {item.observacao}</ProdutoObservacao>}
        </ProdutoInfo>

        {!!remover && (
          <ProdutoBotaoRemover
            onPress={remover || undefined}
            style={{ backgroundColor: `${empresa.configuracoes.tema}` }}
          >
            <ProdutoBotaoRemoverIcone />
          </ProdutoBotaoRemover>
        )}
      </Linha>

      {item.tipoProduto === 'PIZZA' && !!item.sabores.length && (
        <>
          <ProdutoSubItemTitulo>Sabores Extras</ProdutoSubItemTitulo>
          {item.sabores.map((sabor, index) => (
            <Linha
              key={`${sabor.id}-${String(index)}-Carrinho-Sabor`}
              marginTop={4}
            >
              <ProdutoSubItem>{sabor.descricao}</ProdutoSubItem>
              <ProdutoSubItem>{sabor.precoVendaFormatado}</ProdutoSubItem>
            </Linha>
          ))}
        </>
      )}

      {!!item.montagens.length &&
        item.montagens.map((montagem, indexMontagem) => (
          <View
            key={`${montagem.idMontagem
              }-${indexMontagem.toString()}-Carrinho-Montagem`}
          >
            <ProdutoSubItemTitulo>{montagem.descricao}</ProdutoSubItemTitulo>
            {montagem.itens.map((itemMontagem, index) => (
              <Linha
                marginTop={4}
                key={`${itemMontagem.idProd
                  }-${index.toString()}-Carrinho-Montagem-Item`}
              >
                <ProdutoSubItem>{itemMontagem.descricao}</ProdutoSubItem>
                <ProdutoSubItem>
                  {itemMontagem.precoMontagemFormatado}
                </ProdutoSubItem>
              </Linha>
            ))}
          </View>
        ))}

      {!!item.adicionais.length && (
        <>
          <ProdutoSubItemTitulo>Adicionais</ProdutoSubItemTitulo>
          {item.adicionais.map((adicional, index) => (
            <Linha
              key={`${adicional.id}-${String(index)}-Carrinho-Adicional`}
              marginTop={4}
            >
              <ProdutoSubItem>{adicional.descricao}</ProdutoSubItem>
              <ProdutoSubItem>{adicional.precoVendaFormatado}</ProdutoSubItem>
            </Linha>
          ))}
        </>
      )}
      <Linha marginTop={24}>
        <ProdutoTotalTexto>Total</ProdutoTotalTexto>
        <ProdutoTotalValor>{item.totalFormatado}</ProdutoTotalValor>
      </Linha>
    </Produto>
  );
};

export default memo(ProdutoDetalhes);
