import React, { memo, useState } from 'react';

import produtoSemImagemImg from '../../../assets/sem-imagem.png';
import { useSessao } from '../../../hooks/sessao';
import { ALTURA_IMAGEM_PRODUTO } from '../config';
import {
  ProdutoImagem,
  ProdutoContainer,
  ProdutoDescricao,
  ProdutoComposicao,
  PrecoAPatirContainer,
  PrecoAPatir,
  ProdutoPreco,
  CardapioVisualizacao,
} from './styles';

const ProdutoInformacoes = ({ produto }) => {
  const { empresa } = useSessao();
  const { cardapioVisualizacao } = empresa.configuracoes;
  const [source, setSource] = useState(() => {
    if (!produto.imagem) {
      return produtoSemImagemImg;
    }

    return { uri: produto.imagem };
  });

  return (
    <>
      <ProdutoImagem
        altura={ALTURA_IMAGEM_PRODUTO}
        source={source}
        resizeMode={produto.imagem ? 'stretch' : 'contain'}
        onError={() => setSource(produtoSemImagemImg)}
      >
        {cardapioVisualizacao && (
          <CardapioVisualizacao>* SOMENTE VISUALIZAÇÃO *</CardapioVisualizacao>
        )}
      </ProdutoImagem>
      <ProdutoContainer>
        <ProdutoDescricao>{produto.descricao}</ProdutoDescricao>
        <ProdutoComposicao>{produto.composicao}</ProdutoComposicao>

        {produto.menorPreco ? (
          <PrecoAPatirContainer>
            <PrecoAPatir>A partir de</PrecoAPatir>
            <ProdutoPreco>{produto.menorPrecoFormatado}</ProdutoPreco>
          </PrecoAPatirContainer>
        ) : (
          <ProdutoPreco>{produto.precoVendaFormatado}</ProdutoPreco>
        )}
      </ProdutoContainer>
    </>
  );
};

export default memo(ProdutoInformacoes);
