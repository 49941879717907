import React, { useCallback, useEffect, useRef } from 'react';
import { StatusBar, AppState } from 'react-native';
import { enableScreens } from 'react-native-screens';
// import { createNativeStackNavigator as createStackNavigator } from 'react-native-screens/native-stack';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { MaterialIcons as Icon } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';

import { useCarrinho } from '../hooks/carrinho';
import { useSessao } from '../hooks/sessao';
import { useTema } from '../hooks/tema';
import Buscar from '../screens/Buscar';
import BuscarCompleto from '../screens/BuscarCompleto';
import Cardapio from '../screens/Cardapio';
import Carrinho from '../screens/Carrinho';
import Conta from '../screens/Conta';
import Desenvolvedor from '../screens/Desenvolvedor';
import Produto from '../screens/Produto';
import { icons } from '../constants';

import TabIcon from '../components/TabIcon';

enableScreens();
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const tabIcons = {
  Cardapio: 'list-alt',
  Buscar: 'search',
  Favoritos: 'favorite-board',
  Carrinho: 'shopping-cart',
  Conta: 'receipt-long',
  Info: 'info',
};

const HomeTabs = () => {
  const { tema } = useTema();
  const { qtdItens } = useCarrinho();
  const { empresa } = useSessao();
  const { cardapioVisualizacao } = empresa.configuracoes;

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color }) => (
          <Icon name={tabIcons[route.name]} color={color} size={30} />
        ),
      })}
      tabBarOptions={{
        labelStyle: {
          fontSize: 13
        },
        style: {
          height: isIphoneX() ? 140 : 80,
          backgroundColor: tema.cores.superficie,
          borderTopColor: "transparent",
        },
        activeTintColor: empresa.configuracoes.tema,
        inactiveTintColor: tema.cores.cinzas.c2,
      }}
    >
      <Tab.Screen name="Cardapio" component={Cardapio} />
      <Tab.Screen name="Buscar" component={Buscar} />
      {!cardapioVisualizacao && (
        <>
          <Tab.Screen
            name="   Carrinho"
            component={Carrinho}
            options={{
              tabBarBadge: qtdItens || null,
              tabBarBadgeStyle: {
                backgroundColor: tema.cores.primariaClara,
                color: tema.cores.texto.sobrePrimaria,
              },
              tabBarIcon: ({ focused }) => {
                return (
                  <TabIcon
                    focused={focused}
                    icon={icons.carrinho}
                    isCarrinho={true}
                  />
                )
              }
            }}
          />
        </>
      )}
      <Tab.Screen name="Conta" component={Conta} />
      <Tab.Screen name="Info" component={Desenvolvedor} />
    </Tab.Navigator>
  );
};

const AppRoutes = () => {
  const { verificarSessao } = useSessao();
  const appState = useRef(AppState.currentState);

  const handleAppStateChange = useCallback(
    async nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        await verificarSessao();
      }

      appState.current = nextAppState;
    },
    [verificarSessao],
  );

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
  }, []);

  return (
    <>
      <StatusBar
        translucent
        barStyle="dark-content"
        backgroundColor="rgba(0, 0, 0, 0)"
      />
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Home" component={HomeTabs} />
        <Stack.Screen name="Produto" component={Produto} />
        <Stack.Screen name="BuscarCompleto" component={BuscarCompleto} />
      </Stack.Navigator>
    </>
  );
};

export default AppRoutes;
