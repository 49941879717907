import React, { createContext, useCallback, useContext, useState } from 'react';

import { lighten, darken, getContrast } from 'polished';
import { ThemeProvider } from 'styled-components';

import temaConfig from '../theme';

const TemaContext = createContext({});

export const TemaProvider = ({ children }) => {
  const [tema, setTema] = useState(() => temaConfig);

  const gerarTema = useCallback(corPrimaria => {
    const { sobrePrimaria, sobreSuperficie } = temaConfig.cores.texto;
    const contrasteBranco = getContrast(corPrimaria, sobrePrimaria);
    const contrastePreto = getContrast(corPrimaria, sobreSuperficie);

    const coreSobrePrimaria =
      contrastePreto > contrasteBranco ? sobreSuperficie : sobrePrimaria;

    setTema(state => ({
      ...state,
      cores: {
        ...state.cores,
        primaria: corPrimaria,
        primariaEscura: darken(0.1, corPrimaria),
        primariaClara: lighten(0.1, corPrimaria),
        texto: {
          ...state.cores.texto,
          sobrePrimaria: coreSobrePrimaria,
        },
      },
    }));
  }, []);

  return (
    <TemaContext.Provider value={{ tema, gerarTema }}>
      <ThemeProvider theme={tema}>{children}</ThemeProvider>
    </TemaContext.Provider>
  );
};

export function useTema() {
  const context = useContext(TemaContext);

  if (!context) {
    throw new Error('TemaContext must be used within an TemaProvider');
  }

  return context;
}
