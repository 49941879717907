import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Alert,
  StatusBar,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from 'react-native';

import { useNavigation, useRoute } from '@react-navigation/native';
import { debounce } from 'lodash';

import Container from '../../components/Container';
import { useSessao } from '../../hooks/sessao';
import {
  ContainerInterno,
  Cabecalho,
  BotaoVoltar,
  IconeVoltar,
  Texto,
  Input,
  BotaoNovaSessao,
} from './styles';
import ModalPwa from '../../components/ModalPwa';
import { resetSession } from '../../utils/resetSession';

const SessaoNomeCliente = () => {
  const [isVisibleErro, setIsVisibleErro] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const [desabilitado, setDesabilitado] = useState(true);
  const { params } = useRoute();
  const navigation = useNavigation();
  const { iniciarSessao, tema, encerrarSessao } = useSessao();
  const { control, handleSubmit } = useForm();
  const [erro, setErro] = useState('');

  const iniciarNovaSessao = useRef(
    debounce(
      async ({ nomeCliente }) => {
        try {
          setCarregando(true);
          await iniciarSessao({
            idModulo: params.idModulo,
            tipoModulo: params.tipoModulo.trim(),
            nomeCliente,
            contrato: params.contrato,
            vendaSemAutenticacao: true,
            verCategorias: true,
            verChamaGarcom: true,
            orderDescricao: true,
            utilizaCouvert: 'True',
            precoCouvert: 0,
            moduloMesaCobrarCouvertNaAbertura: true,
            moduloMesaQtdeCouvertNaAbertura: 1,
            moduloCartaoCobrarCouvertNaAbertura: true,
            moduloCartaoQtdeCouvertNaAbertura: 1,
            vendaBloqueadaCartaoLivre: true,
          });

          if (params.verIdentificacaoCliente && params.temporizadorSessao) {
            resetSession(params.tempoExpiracaoSessao, encerrarSessao);
          }
        } catch (error) {
          console.log(error.message);
          setCarregando(false);
          if (error.response) {
            if (Platform.OS === 'web') {
              setIsVisibleErro(true);
            }

            Alert.alert('MenuCheff', error.response.data.erro);
            setErro(error.response.data.erro);
          } else {
            if (Platform.OS === 'web') {
              setIsVisibleErro(true);
            }
            setErro('Erro ao entrar');
            Alert.alert('MenuCheff', 'Erro ao entrar');
          }
        }
      },
      500,
      { leading: true, trailing: false },
    ),
  );

  return (
    <KeyboardAvoidingView
      style={{
        flex: 1,
      }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      {Platform.OS === 'web' && (
        <ModalPwa
          visivel={isVisibleErro}
          titulo={erro}
          onClose={() => setIsVisibleErro(false)}
          swipeDirection={null}
        />
      )}
      <ScrollView
        keyboardShouldPersistTaps="handled"
        scrollable={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Container>
          <StatusBar
            barStyle="dark-content"
            backgroundColor={tema.cores.superficie}
          />

          <Cabecalho>
            <BotaoVoltar
              disabled={carregando}
              onPress={() => navigation.goBack()}
            >
              <IconeVoltar />
            </BotaoVoltar>
            <Texto>Digite seu nome</Texto>
          </Cabecalho>
          <ContainerInterno>
            <Controller
              control={control}
              name="nomeCliente"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  placeholder="Digite seu nome!"
                  autoCompleteType="name"
                  autoFocus
                  textAlign="center"
                  returnKeyType="send"
                  onBlur={onBlur}
                  onChangeText={texto => {
                    setDesabilitado(texto.length < 3);
                    onChange(texto);
                  }}
                  value={value}
                  onSubmitEditing={handleSubmit(iniciarNovaSessao.current)}
                />
              )}
            />

            <BotaoNovaSessao
              desabilitado={carregando || desabilitado}
              carregando={carregando}
              onPress={handleSubmit(iniciarNovaSessao.current)}
            >
              Entrar
            </BotaoNovaSessao>
          </ContainerInterno>
        </Container>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default SessaoNomeCliente;
