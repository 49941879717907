import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import Botao from '../../components/Botao';
import theme from '../../theme/index';

export const Titulo = styled.Text`
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.grande};
  font-weight: bold;
  margin: 16px;
`;

export const CarrinhoVazioContainer = styled.View`
  ${Platform.select({
  web: css`
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin-top: 10vh;
    `,
  android: css`
      background-color: transparent;
      align-items: center;
      justify-content: center;
      flex: 10;
      padding: 16px;
    `,
  ios: css`
      background-color: transparent;
      align-items: center;
      justify-content: center;
      flex: 10;
      padding: 16px;
    `,
})}
`;

export const CarrinhoVazioImagem = styled.Image`
  height: 200px;
  width: 200px;
`;

export const CarrinhoVazioTitulo = styled(Titulo)`
  margin: 16px 16px 0 16px;
  font-size: ${theme.fontes.extraGrande};
`;

export const CarrinhoVazioMensagem = styled.Text`
  color: ${theme.cores.cinzas.c2};
  font-size: ${theme.fontes.media};
  text-align: center;
  font-weight: bold;
  margin-top: 4px;
`;

export const Produtos = styled.FlatList.attrs(() => ({
  showsVerticalScrollIndicator: false,
}))`
  ${Platform.select({
  web: css`
      height: 100vh;
      margin-top: 16px;
    `,
  android: css`
      flex: 50;
      margin-top: 16px;
    `,
  ios: css`
      flex: 50;
      margin-top: 16px;
    `,
})}
`;

export const ViewBotao = styled.View`
  ${Platform.select({
  web: css``,
  android: css``,
  ios: css``,
})}
`;

export const BotaoPedir = styled(Botao)`
  ${Platform.select({
  web: css`
      background-color: ${({ desabilitado }) =>
      desabilitado ? theme.cores.cinzas.c3 : theme.cores.unitech};
      margin: 16px;
      margin-bottom: 40%;
    `,
  android: css`
      background-color: ${({ desabilitado }) =>
      desabilitado ? theme.cores.cinzas.c3 : theme.cores.unitech};
      margin: 16px;
    `,
  ios: css`
      background-color: ${({ desabilitado }) =>
      desabilitado ? theme.cores.cinzas.c3 : theme.cores.unitech};
      margin: 16px;
    `,
})}
`;

export const Input = styled.TextInput`
  font-size: ${theme.fontes.grande};
  color: ${theme.cores.texto.sobreSuperficie};
  background-color: ${theme.cores.superficie};
  padding: 8px 12px;
  text-align: center;
  flex: 1;
`;

export const ViewInput = styled.View``;

export const BotaoPedirContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BotaoPedirTexto = styled.Text`
  margin-right: 6px;
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobrePrimaria};
  font-weight: bold;
`;

export const TotalValor = styled(BotaoPedirTexto)`
  margin-right: 0;
`;
