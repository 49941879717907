import { Platform } from 'react-native';
import { Checkbox, RadioButton } from 'react-native-paper';

import { MaterialIcons } from '@expo/vector-icons';
import styled, { css } from 'styled-components/native';

import BotaoBase from '../../components/BotaoBase';
import ImagemFallback from '../../components/ImagemFallback';
import theme from '../../theme/index';

import { Dimensions } from 'react-native';

const windowHeight = Dimensions.get('window').height;

export const Container = styled.View`
  ${Platform.select({
    web: css`
      background-color: ${theme.cores.superficie};
      height: ${windowHeight}px;
    `,
    android: css`
      flex: 1;
      background-color: ${theme.cores.superficie};
    `,
    ios: css`
      flex: 1;
      background-color: ${theme.cores.superficie};
    `,
  })}
`;

export const Secao = styled.View`
  background-color: ${theme.cores.superficie};
  bottom: 40px;
`;

export const SecaoTituloContainer = styled.View`
  background-color: ${theme.cores.cinzas.c1};
  padding: 16px;
  align-items: flex-start;
  position: relative;
`;

export const SecaoTituloContainerTamanho = styled.View`
  background-color: ${theme.cores.cinzas.c1};
  padding: 16px;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const SecaoTitulo = styled.Text`
  font-size: ${theme.fontes.normal};
  line-height: ${theme.fontes.normal};
  font-weight: bold;
  color: ${theme.cores.cinzas.c4};
`;

export const SecaoDescricao = styled.Text`
  font-size: ${theme.fontes.media};
  line-height: ${theme.fontes.media};
  color: ${theme.cores.cinzas.c3};
  margin-top: 8px;
`;

export const SecaoDescricaoDestaque = styled(SecaoDescricao)`
  font-weight: bold;
`;

export const SecaoBotaoContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ViewFlex = styled.View`
  flex-direction: column;
  flex:1;
`;

export const SecaoBotao = styled(BotaoBase)`
  padding: 8px 12px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ desabilitado }) =>
    desabilitado ? theme.cores.cinzas.c2 : theme.cores.cinzas.c2};
`;

export const SecaoBotaoTexto = styled.Text`
  font-size: ${theme.fontes.normal};
  line-height: ${theme.fontes.normal};
  color: ${({ desabilitado }) =>
    desabilitado ? theme.cores.cinzas.c2 : theme.cores.texto.sobrePrimaria};
  margin-left: 8px;
`;

export const SecaoBotaoIconeAdd = styled(MaterialIcons).attrs(
  ({ desabilitado }) => ({
    size: 25,
    color: desabilitado
      ? theme.cores.cinzas.c2
      : theme.cores.texto.sobrePrimaria,
    name: 'add',
  }),
)``;

export const SecaoAvisoContainer = styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const SecaoAviso = styled.Text`
  font-size: ${theme.fontes.media};
  line-height: ${theme.fontes.media};
  border-radius: 4px;
  padding: 6px 8px;
  background-color: ${theme.cores.cinzas.c4};
  color: #fff;
  align-self: flex-start;
  margin-top: 8px;
`;

export const SecaoConteudo = styled.View`
  background-color: ${theme.cores.superficie};
  padding: 0 16px;
`;

export const SecaoVazia = styled.View`
  padding: 50px;
`;

export const SecaoVaziaTexto = styled.Text`
  font-size: ${theme.fontes.normal};
  line-height: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c3};
  text-align: center;
`;

export const ItemContainer = styled.TouchableOpacity``;

export const Item = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  ${props =>
    props.divisor &&
    css`
      border-top-width: 1px;
      border-top-color: ${theme.cores.cinzas.c1};
    `}
`;

export const ItemCheckbox = styled(Checkbox).attrs(() => ({
  color: theme.cores.primaria,
}))``;

export const ItemRadioButton = styled(RadioButton).attrs(() => ({}))``;

export const ItemInfo = styled.View`
  ${Platform.select({
    web: css`
      margin-right: 8px;
    `,
    android: css`
      flex: 1;
      margin-right: 8px;
    `,
    ios: css`
      flex: 1;
      margin-right: 8px;
    `,
  })}
`;

export const ItemImagem = styled(ImagemFallback)`
  height: 50px;
  width: 50px;
  border-radius: 4px;
  margin-right: 8px;
`;

export const ItemNome = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreSuperficie};
`;

export const ItemDescricao = styled.Text`
  font-size: ${theme.fontes.media};
  margin-top: 4px;
  font-weight: bold;
  color: ${theme.cores.cinzas.c3};
`;

export const ItemBotao = styled(BotaoBase)`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
`;

export const ItemBotaoIconeRemover = styled(MaterialIcons).attrs(() => ({
  size: 25,
  color: theme.cores.primaria,
  name: 'delete',
}))``;
