import React from 'react';
import { useSessao } from '../hooks/sessao';
import {
    View,
    Image
} from 'react-native';

import { FONTS, COLORS } from "../constants"

const TabIcon = ({ focused, icon, iconStyle, label, isCarrinho }) => {
  const { empresa } = useSessao();
    if (isCarrinho) {
        return (
            <View
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: empresa.configuracoes.tema
                }}
            >
                <Image
                    source={icon}
                    resizeMode="contain"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 30,
                        height: 30,
                        tintColor: COLORS.white,
                        ...iconStyle
                    }}
                />
            </View>
        )
    }
}

export default TabIcon;
