import React, { useEffect, useState } from 'react';
import {
  Image,
  StyleSheet,
  ScrollView,
  StatusBar,
  Platform,
  Linking,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';

import {
  TextInput,
  BotaoIr,
  ViewImage,
  ContainerInit,
  Container,
  TextInfo,
  ContainerUso,
} from './styles';
import { useSessao } from '../../hooks/sessao';
import { getConfig } from '../../services/config';

const useMount = func => useEffect(() => func(), []);

const Tela = () => {
  const useInitialURL = () => {
    const [url, setUrl] = useState(null);

    useMount(() => {
      const getUrlAsync = async () => {
        // Get the deep link used to open the app
        const initialUrl = await Linking.getInitialURL();

        // The setTimeout is just for testing purpose
        setTimeout(() => {
          setUrl(initialUrl);
        }, 1000);
      };

      getUrlAsync();
    });

    return { url };
  };

  const navigation = useNavigation();
  const { url: initialUrl } = useInitialURL();
  const codigoCapturado = `${initialUrl}`;
  const codigoSplit = codigoCapturado.split('/');
  const codigoFinal = `${codigoSplit[3]}`;
  const { iniciarSessao } = useSessao();

  useEffect(() => {
    const regex = /^(\d+)(M|C)(\d+)$/i;

    let codigo = '';
    codigo = codigoFinal.toUpperCase();

    if (regex.test(codigo)) {
      const res = codigo.match(regex);

      const dados = {
        contrato: res[3],
        idModulo: res[1],
        tipoModulo: res[2] === 'M' ? 'MESA' : 'CARTAO',
      };

      loadConfig(dados);
    } else if (Platform.OS === 'web') {
      navigation.navigate('NotFound');
    }
  }, [codigoFinal, navigation]);

  const loadConfig = useCallback(async (dados) => {
    try {
      const config = await getConfig(dados.contrato);

      if (!config?.verIdentificacaoCliente) {
        iniciarSessao({
          idModulo: dados.idModulo,
          tipoModulo: dados.tipoModulo,
          nomeCliente: 'Consumidor',
          contrato: dados.contrato,
          vendaSemAutenticacao: true,
          verCategorias: true,
          verChamaGarcom: true,
          orderDescricao: true,
          utilizaCouvert: 'True',
          precoCouvert: 0,
          moduloMesaCobrarCouvertNaAbertura: true,
          moduloMesaQtdeCouvertNaAbertura: 1,
          moduloCartaoCobrarCouvertNaAbertura: true,
          moduloCartaoQtdeCouvertNaAbertura: 1,
          vendaBloqueadaCartaoLivre: false,
        });
      } else {
        navigation.navigate('SessaoNomeCliente', dados);
      }
    } catch (error) {
      navigation.navigate('SessaoNomeCliente', dados);
      console.log({ error })
    };
  }, [iniciarSessao, navigation]);

  const styles = StyleSheet.create({
    image: {
      width: 200,
      height: 200,
    },
  });

  function sessao() {
    navigation.navigate('SessaoPorQrCode');
  }

  function sessaoPwa() {
    navigation.navigate('SessaoCodigo');
  }

  return (
    <>
      <StatusBar backgroundColor="#003366" barStyle="light-content" />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        scrollable
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Container>
          <ContainerInit>
            <TextInput>Bem vindo ao MenuCheff</TextInput>
          </ContainerInit>
          <ViewImage>
            <Image
              source={require('../../../assets/icon.png')}
              style={styles.image}
              resizeMode="cover"
            />
          </ViewImage>

          <BotaoIr
            onPress={() => {
              if (Platform.OS === 'web') {
                sessaoPwa();
              } else {
                sessao();
              }
            }}
          >
            Ler QR code na mesa
          </BotaoIr>

          <ContainerUso>
            <TextInfo>Leia o QR code para acessar ao cardápio.</TextInfo>
          </ContainerUso>
        </Container>
      </ScrollView>
    </>
  );
};

export default Tela;
