import React, { memo } from 'react';

import BotaoQuantidade from '../BotaoQuantidade';
import {
  Item,
  ItemDescricao,
  ItemInfo,
  ItemNome,
  Secao,
  SecaoAviso,
  SecaoConteudo,
  SecaoDescricao,
  SecaoDescricaoDestaque,
  SecaoTitulo,
  SecaoTituloContainer,
} from '../globalStyles';

// import { Container } from './styles';

const Montagem = ({
  montagem,
  montagemIndex,
  adicionarItem,
  removerItem,
  adicionarDesabilitado,
  tamanhoSelecionado
}) => {
  return (
    <Secao>
      <SecaoTituloContainer>
        <SecaoTitulo>{montagem.descricao}</SecaoTitulo>
        <SecaoDescricao>
          Escolha até{' '}
          <SecaoDescricaoDestaque>{montagem.qtdMax}</SecaoDescricaoDestaque>{' '}
          {montagem.qtdMax > 1 ? 'itens' : 'item'}
        </SecaoDescricao>
        {montagem.qtdSemCusto > 0 && (
          <SecaoDescricao>
            <SecaoDescricaoDestaque>
              {montagem.qtdSemCusto}
            </SecaoDescricaoDestaque>{' '}
            {montagem.qtdSemCusto > 1 ? 'itens gratuitos' : 'item gratuito'}
          </SecaoDescricao>
        )}
        {!montagem.addItemMaisVezes && (
          <SecaoDescricao>
            <SecaoDescricaoDestaque>1</SecaoDescricaoDestaque> quantidade por
            item
          </SecaoDescricao>
        )}

        {montagem.obrigatorio && <SecaoAviso>Obrigatório</SecaoAviso>}
      </SecaoTituloContainer>
      <SecaoConteudo>
        {montagem.opcoes.map((item, itemIndex) => {
          return (
            <Item key={`${item.id}-Montagem-Opcao`} divisor={!!itemIndex}>
              <ItemInfo>
                <ItemNome>{item.descricao}</ItemNome>
                {tamanhoSelecionado.descricao === 'Pequena' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaPequena).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {tamanhoSelecionado.descricao === 'Média' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaMedia).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {tamanhoSelecionado.descricao === 'Grande' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaGrande).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {tamanhoSelecionado.descricao === 'Gigante' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaGigante).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {tamanhoSelecionado.descricao === 'Brotinho' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaBrotinho).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {tamanhoSelecionado.descricao === 'Big' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaBig).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {tamanhoSelecionado.descricao === 'Familia' && (
                    <ItemDescricao>R$ {String(Number(item.pizzaFamilia).toFixed(2)).replace(".", ",")}</ItemDescricao>
                  )
                }
                {/* <ItemDescricao>{item.precoVendaFormatado}</ItemDescricao> */}
              </ItemInfo>

              <BotaoQuantidade
                semBorda
                desabilitado={adicionarDesabilitado}
                qtdMaxima={!montagem.addItemMaisVezes ? 1 : undefined}
                aumentar={() => adicionarItem(item, montagem.id, montagemIndex)}
                diminuir={() =>
                  removerItem(item.id, montagem.id, montagemIndex)
                }
              />
            </Item>
          );
        })}
      </SecaoConteudo>
    </Secao>
  );
};

export default memo(Montagem);
