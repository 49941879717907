import { Platform } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const ContainerInput = styled.View`
  padding: 16px;
  padding-left: 0;
  background-color: ${theme.cores.primaria};
  flex-direction: row;
  align-items: center;
`;

export const BotaoVoltar = styled.TouchableOpacity`
  ${Platform.select({
    web: css`
      position: relative;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin: 0 10px;

      background-color: rgba(0, 0, 0, 0.1);
    `,
    android: css`
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin: 0 10px;

      background-color: rgba(0, 0, 0, 0.1);
    `,
    ios: css`
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin: 0 10px;

      background-color: rgba(0, 0, 0, 0.1);
    `,
  })}
`;

export const BotaoVoltarIcone = styled(MaterialIcons).attrs(() => ({
  name: 'arrow-back',
  size: 25,
  color: theme.cores.fundo,
}))``;

export const Filtros = styled.FlatList.attrs(() => ({
  showsHorizontalScrollIndicator: false,
}))`
  ${Platform.select({
    web: css`
      margin: 16px;
      flex-grow: 0;
    `,
    android: css`
      margin: 16px;
      flex-grow: 0;
    `,
    ios: css`
      margin: 16px;
      flex-grow: 0;
    `,
  })}
`;

export const Filtro = styled.TouchableOpacity`
  margin: 0 4px;
  padding: 4px 16px;
  height: 35px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ selecionado }) =>
    selecionado ? theme.cores.primaria : theme.cores.cinzas.c2};
  border-radius: 16px;
`;

export const FiltroNome = styled.Text`
  color: ${theme.cores.texto.sobreSuperficie};
  font-size: ${theme.fontes.media};
  line-height: ${theme.fontes.media};
  margin-right: 4px;
  flex: 1;
`;

export const FiltroIcone = styled(MaterialIcons).attrs(() => ({
  name: 'expand-more',
  size: 20,
  color: theme.cores.texto.sobreSuperficie,
}))`
  margin-left: 6px;
`;

export const LimparFiltros = styled(Filtro)`
  background-color: ${theme.cores.cinzas.c1};
`;

export const Carregando = styled.View`
  ${Platform.select({
    web: css`
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
    `,
    android: css`
      flex: 1;
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
    `,
    ios: css`
      flex: 1;
      align-items: center;
      justify-content: center;
      color: ${theme.cores.fundo};
    `,
  })}
`;

export const Resultado = styled.Text`
  ${Platform.select({
    web: css`
      color: ${theme.cores.texto.sobreSuperficie};
      font-size: ${theme.fontes.normal};
      font-weight: bold;
      margin: 16px;
    `,
    android: css`
      color: ${theme.cores.texto.sobreSuperficie};
      font-size: ${theme.fontes.normal};
      font-weight: bold;
      margin: 16px;
    `,
    ios: css`
      color: ${theme.cores.texto.sobreSuperficie};
      font-size: ${theme.fontes.normal};
      font-weight: bold;
      margin: 16px;
    `,
  })}
`;

export const ErroTexto = styled.Text`
  font-size: ${theme.fontes.grande};
  color: ${theme.cores.texto.sobreFundo};
  font-weight: bold;
  margin: 24px 16px;
  text-align: center;
`;
