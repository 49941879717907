import api from './api';

export async function listarProdutos({
  pagina = 1,
  limite = 10,
  grupoId,
  produtoNome,
  order,
}) {
  const res = await api.get(`produtos`, {
    params: {
      pagina,
      limite,
      grupoId,
      produtoNome,
      order
    },
  });
  return res.data;
}

export async function buscarProduto({ idProduto }) {
  const res = await api.get(`produtos/${idProduto}`);
  return res.data;
}
