import React, { memo } from 'react';

import Montagem from '../Montagem';

// import { Container } from './styles';

const Montagens = ({
  montagens,
  adicionarItemMontagem,
  removerItemMontagem,
  itensMontagens,
  tamanhoSelecionado
}) => {
  return montagens.map((montagem, index) => (
    <Montagem
      key={`${montagem.id}-Montagem-Produto`}
      montagem={montagem}
      montagemIndex={index}
      adicionarItem={adicionarItemMontagem}
      removerItem={removerItemMontagem}
      tamanhoSelecionado={tamanhoSelecionado}
      adicionarDesabilitado={
        itensMontagens[montagem.id]
          ? itensMontagens[montagem.id].length === montagem.qtdMax
          : false
      }
    />
  ));
};

export default memo(Montagens);
