import { Platform } from 'react-native';

import styled, { css } from 'styled-components/native';

import ContainerComponente from '../../components/Container';
import theme from '../../theme/index';

export const Container = styled(ContainerComponente).attrs(() => ({
  corStatusBar: '#000',
}))`
  ${Platform.select({
    web: css`
      background-color: #000;
      position: relative;

      align-items: center;
      justify-content: center;
      flex: 1;
    `,
    android: css`
      background-color: #000;
      position: relative;

      align-items: center;
      justify-content: center;
    `,
    ios: css`
      background-color: #000;
      position: relative;

      align-items: center;
      justify-content: center;
    `,
  })}
`;

export const TextoInfo = styled.Text`
  font-size: ${theme.fontes.normal};
  font-weight: bold;
  color: #424242;
  text-align: center;
  margin-top: 16px;
`;

export const InfoContainer = styled.View`
  padding: 24px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
`;

export const TextoInfoContainer = styled(TextoInfo)`
  margin-top: 0;
  color: #fff;
  font-weight: normal;
`;

export const BotaoCod = styled.TouchableOpacity`
  padding: 24px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
