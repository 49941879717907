import React, { memo } from 'react';

import BotaoQuantidade from '../BotaoQuantidade';
import {
  Secao,
  SecaoDescricao,
  SecaoTitulo,
  SecaoTituloContainer,
  SecaoDescricaoDestaque,
  SecaoConteudo,
  Item,
  ItemInfo,
  ItemNome,
  ItemDescricao,
} from '../globalStyles';

const Adicionais = ({
  adicionais,
  aumentarAdicional,
  diminuirAdicional,
  aumentarAdicionalDesabilitado,
  qtdMaxAdicionais,
}) => {
  return (
    <Secao>
      <SecaoTituloContainer>
        <SecaoTitulo>Adicionais</SecaoTitulo>
        {qtdMaxAdicionais ? (
          <SecaoDescricao>
            Escolha até{' '}
            <SecaoDescricaoDestaque>{qtdMaxAdicionais}</SecaoDescricaoDestaque>{' '}
            {qtdMaxAdicionais === 1 ? 'adicional' : 'adicionais'}
          </SecaoDescricao>
        ) : (
          <SecaoDescricao>Escolha os adicionais</SecaoDescricao>
        )}
      </SecaoTituloContainer>
      <SecaoConteudo>
        {adicionais.map((adicional, index) => (
          <Item key={`${adicional.id}-Produto`} divisor={!!index}>
            <ItemInfo>
              <ItemNome>{adicional.descricao}</ItemNome>
              <ItemDescricao>{adicional.precoVendaFormatado}</ItemDescricao>
            </ItemInfo>
            <BotaoQuantidade
              semBorda
              qtdMaxima={qtdMaxAdicionais || 1}
              desabilitado={aumentarAdicionalDesabilitado}
              aumentar={() => aumentarAdicional(adicional)}
              diminuir={() => diminuirAdicional(adicional.id)}
            />
          </Item>
        ))}
      </SecaoConteudo>
    </Secao>
  );
};

export default memo(Adicionais);
