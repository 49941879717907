import React, { useState, useEffect } from 'react';
import { Alert, Linking, Platform } from 'react-native';
import ModalPwaGarcom from '../../../components/ModalPwaGarcom';

import { useSessao } from '../../../hooks/sessao';
const useMount = func => useEffect(() => func(), []);

import {
  Container,
  EmpresaEndereco,
  EmpresaInfo,
  EmpresaLogo,
  EmpresaNome,
  Modulo,
  ModuloBotaoGarcom,
  ModuloBotaoSair,
  ModuloContainer,
} from './styles';

const Cabecalho = () => {
  const { empresa, sessao, encerrarSessao, chamaGarcom } = useSessao();
  const [isVisibleGarcom, setIsVisibleGarcom] = useState(false);
  const useInitialURL = () => {
    const [url, setUrl] = useState(null);

    useMount(() => {
      const getUrlAsync = async () => {
        const initialUrl = await Linking.getInitialURL();

        setTimeout(() => {
          setUrl(initialUrl);
        }, 1000);
      };

      getUrlAsync();
    });

    return { url };
  };

  const { url: initialUrl } = useInitialURL();
  const codigoCapturado = `${initialUrl}`;
  const codigoSplit = codigoCapturado.split('/');
  const codigoFinal = `${codigoSplit[3]}`;

  const regex = /^(\d+)(M|C)(\d+)$/i;

  let codigo = '';
  codigo = codigoFinal.toUpperCase();

  let nrModulo = '';

  if (regex.test(codigo)) {
    const res = codigo.match(regex);

    const dados = {
      contrato: res[3],
      idModulo: res[1],
      tipoModulo: res[2] === 'M' ? 'MESA' : 'CARTAO',
    };
    nrModulo = dados.idModulo;


    if (nrModulo === '0') {
      sessao.empresa.configuracoes.cardapioVisualizacao = true;
      sessao.vendaBloqueadaCartaoLivre = false;
    }
    if (sessao.nrModulo.toString() !== nrModulo && nrModulo !== '0') {
        encerrarSessao();
    }
  }

  return (

    <Container style={{ backgroundColor: `${empresa.configuracoes.tema}` }}>
      {Platform.OS === 'web' && (
        <ModalPwaGarcom
          visivel={isVisibleGarcom}
          titulo={'O garçom foi notificado da sua solicitação.'}
          onClose={() => setIsVisibleGarcom(false)}
          swipeDirection={null}
        />
      )}
      <EmpresaLogo source={{ uri: empresa.logo }} resizeMode="cover" />
      <EmpresaInfo>
        <EmpresaNome>{empresa.nomeFantasia}</EmpresaNome>
        <EmpresaEndereco>{empresa.enderecoCompleto}</EmpresaEndereco>
        <ModuloContainer>
          <Modulo>
            {sessao.tipoModulo} {Platform.OS === 'android' ? sessao.nrModulo : nrModulo}
          </Modulo>
          {sessao.empresa.configuracoes.verChamaGarcom === true &&
            <ModuloBotaoGarcom onPress={() => {
              chamaGarcom({idModulo: sessao.nrModulo, contrato: sessao.empresa.id})
              if (Platform.OS === 'web') {
                setIsVisibleGarcom(true);
              } else {
                Alert.alert('MenuCheff', 'O garçom foi notificado');
              }
            }}>Garçom</ModuloBotaoGarcom>
         }
         {sessao.sessaoCardapioInfinita === 0 &&(
          <ModuloBotaoSair onPress={encerrarSessao}>Sair</ModuloBotaoSair>
         )}
        </ModuloContainer>
      </EmpresaInfo>
    </Container>
  );
};

export default Cabecalho;
