import { MaterialIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';

import theme from '../../theme/index';
import BotaoBase from '../BotaoBase';
import ImagemFallback from '../ImagemFallback';

export const Produto = styled.View`
  background-color: ${theme.cores.superficie};
  margin: 0 16px 16px;
  padding: 8px;
  border-radius: 4px;
`;

export const Linha = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`;

export const ProdutoImagem = styled(ImagemFallback)`
  height: 40px;
  width: 40px;
  border-radius: 4px;
`;

export const ProdutoInfo = styled.View`
  flex: 1;
`;

export const ProdutoDescricao = styled.Text.attrs(() => ({
  ellipsizeMode: 'tail',
  numberOfLines: 1,
}))`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.texto.sobreSuperficie};
  font-weight: bold;
`;

export const ProdutoPrecoContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
`;

export const ProdutoQuantidade = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c4};
  margin-top: 2px;
`;

export const ProdutoObservacao = styled.Text`
  font-size: ${theme.fontes.extraPequena};
  color: ${theme.cores.cinzas.c4};
  margin-top: 2px;
  font-weight: bold;
`;

export const ProdutoPreco = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c4};
  margin-top: 2px;
`;

export const ProdutoBotaoRemover = styled(BotaoBase)`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;
export const ProdutoBotaoRemoverIcone = styled(MaterialIcons).attrs(() => ({
  size: 25,
  color: theme.cores.texto.sobrePrimaria,
  name: 'delete',
}))``;

export const ProdutoSubItemTitulo = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c3};
  font-weight: bold;
  margin-top: 16px;
`;

export const ProdutoSubItem = styled.Text`
  font-size: ${theme.fontes.media};
  color: ${theme.cores.cinzas.c2};
`;

export const ProdutoTotalTexto = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.cinzas.c4};
  font-weight: bold;
`;

export const ProdutoTotalValor = styled.Text`
  font-size: ${theme.fontes.normal};
  color: ${theme.cores.sucesso};
  font-weight: bold;
`;
