import React, { memo, useCallback } from 'react';
import { ActivityIndicator } from 'react-native';

import { useTema } from '../../hooks/tema';
import ProdutoCard from '../ProdutoCard';
import { ProdutosLista, Carregando } from './styles';

const Produtos = ({
  produtos,
  paginacao,
  total,
  chave,
  onPressProduto,
  produtoVisivel,
}) => {
  const { tema } = useTema();

  const renderProduto = useCallback(
    ({ item }) => (
      <ProdutoCard
        visivel={produtoVisivel ? produtoVisivel(item) : true}
        onPress={() => onPressProduto(item)}
        produto={item}
      />
    ),
    [onPressProduto, produtoVisivel],
  );

  const keyExtractor = useCallback(
    item => `${item.id.toString()}-produtos-${chave}`,
    [chave],
  );

  const renderFooter = useCallback(
    () =>
      total !== produtos.length && (
        <Carregando>
          <ActivityIndicator size={30} color={tema.cores.primaria} />
        </Carregando>
      ),
    [produtos.length, tema.cores.primaria, total],
  );

  return (
    <ProdutosLista
      data={produtos}
      keyExtractor={keyExtractor}
      onEndReached={paginacao}
      onEndReachedThreshold={0.7}
      renderItem={renderProduto}
      maxToRenderPerBatch={5}
      windowSize={10}
      ListFooterComponent={renderFooter}
    />
  );
};

export default memo(Produtos);
