import api from './api';

export async function criarGarcom({
  idModulo,
  nrContrato
}) {
  try {
    const res = await api.post('/garcom', {
      idModulo,
      nrContrato
    });
    return res.data;
  } catch (error) {
    console.log(error.message);
  }

}
