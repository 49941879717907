import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { debounce } from 'lodash';
import { useSessao } from '../../hooks/sessao';

import {
  Platform,
  Image,
  StyleSheet,
  ScrollView,
  StatusBar,
  Linking,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { TextInput, ViewImage, ContainerInit, Container } from './styles';
import { getConfig } from '../../services/config';

const useMount = func => useEffect(() => func(), []);

const TelaInicialPwa = () => {
  const { encerrarSessao, iniciarSessao } = useSessao();
  const { control, handleSubmit } = useForm();
  const [nomeCliente, setNomeCliente] = useState('Visualização');

  const useInitialURL = () => {
    const [url, setUrl] = useState(null);

    useMount(() => {
      const getUrlAsync = async () => {
        // Get the deep link used to open the app
        encerrarSessao();
        const initialUrl = await Linking.getInitialURL();
        // The setTimeout is just for testing purpose
        setTimeout(() => {
          setUrl(initialUrl);
        }, 1000);
      };

      getUrlAsync();
    });

    return { url };
  };
  const navigation = useNavigation();
  const { url: initialUrl } = useInitialURL();
  const codigoCapturado = `${initialUrl}`;
  const codigoSplit = codigoCapturado.split('/');
  const codigoFinal = `${codigoSplit[3]}`;

  const loadConfig = useCallback(
    async dados => {
      try {
        const config = await getConfig(dados.contrato);
        if (!config?.verIdentificacaoCliente) {
          setNomeCliente('Consumidor');
          iniciarSessao({
            idModulo: dados.idModulo,
            tipoModulo: dados.tipoModulo,
            nomeCliente,
            contrato: dados.contrato,
            vendaSemAutenticacao: true,
            verCategorias: true,
            verChamaGarcom: true,
            orderDescricao: true,
            utilizaCouvert: 'True',
            precoCouvert: 0,
            moduloMesaCobrarCouvertNaAbertura: true,
            moduloMesaQtdeCouvertNaAbertura: 1,
            moduloCartaoCobrarCouvertNaAbertura: true,
            moduloCartaoQtdeCouvertNaAbertura: 1,
            vendaBloqueadaCartaoLivre: false,
          });
        } else {
          dados.verIdentificacaoCliente =
            config?.verIdentificacaoCliente ?? false;
          dados.temporizadorSessao = config?.temporizadorSessao ?? false;
          dados.tempoExpiracaoSessao = config?.tempoExpiracaoSessao;
          navigation.navigate('SessaoNomeCliente', dados);
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [nomeCliente, iniciarSessao, navigation],
  );

  useEffect(() => {
    const regex = /^(\d+)(M|C)(\d+)$/i;

    let codigo = '';
    codigo = codigoFinal.toUpperCase();

    if (regex.test(codigo)) {
      const res = codigo.match(regex);

      const dados = {
        contrato: res[3],
        idModulo: res[1],
        tipoModulo: res[2] === 'M' ? 'MESA' : 'CARTAO',
      };
      if (dados.idModulo === '0') {
        setNomeCliente('Visualização');
        iniciarSessao({
          idModulo: '1',
          tipoModulo: dados.tipoModulo,
          nomeCliente,
          contrato: dados.contrato,
          vendaSemAutenticacao: true,
          verCategorias: true,
          verChamaGarcom: true,
          orderDescricao: true,
          utilizaCouvert: 'True',
          precoCouvert: 0,
          moduloMesaCobrarCouvertNaAbertura: true,
          moduloMesaQtdeCouvertNaAbertura: 1,
          moduloCartaoCobrarCouvertNaAbertura: true,
          moduloCartaoQtdeCouvertNaAbertura: 1,
          vendaBloqueadaCartaoLivre: false,
        });
      } else {
        loadConfig(dados);
      }
    } else if (Platform.OS === 'web') {
      navigation.navigate('NotFound');
    }
  }, [codigoFinal, navigation]);
  const styles = StyleSheet.create({
    image: {
      width: 200,
      height: 200,
    },
  });
  return (
    <>
      <StatusBar backgroundColor="#003366" barStyle="light-content" />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        scrollable
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Container>
          <ContainerInit>
            <TextInput>MenuCheff</TextInput>
          </ContainerInit>
          <ViewImage>
            <Image
              source={require('../../../assets/icon.png')}
              style={styles.image}
              resizeMode="cover"
            />
          </ViewImage>
        </Container>
      </ScrollView>
    </>
  );
};

export default TelaInicialPwa;
