import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ToastAndroid, Platform } from 'react-native';

import produce from 'immer';

import precoTamanhosPizza from '../../../config/precoTamanhosPizza';
import qtdTamanhos from '../../../config/qtdTamanhos';
import STATUS from '../../../config/status';
import usaTamanhos from '../../../config/usaTamanhos';
import { useCarrinho } from '../../../hooks/carrinho';
import { buscarProduto } from '../../../services/produtos';
import formatarValor from '../../../utils/formatarValor';

const ProdutoContext = createContext({});

export const ProdutoProvider = ({ children, idProduto, empresa }) => {
  const { adicionar } = useCarrinho();

  const [status, setStatus] = useState(STATUS.CARREGANDO);
  const [quantidade, setQuantidade] = useState(1);
  const [produto, setProduto] = useState({
    id: 0,
    descricao: '',
    composicao: '',
    imagem: '',
    precoVenda: 0,
    precoVendaFormatado: 'R$ 0,00',
    menorPreco: 0,
    menorPrecoFormatado: 'R$ 0,00',
    qtdMaxAdicional: 0,
    adicionais: [],
    tamanhos: [],
    bordas: [],
    montagens: [],
    montagemPrato: false,
  });
  const [adicionais, setAdicionais] = useState([]);
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState({});
  const [quantidadeSabores, setQuantidadeSabores] = useState(0);
  const [saboresSelecionados, setSaboresSelecionados] = useState([]);
  const [precoTamanhoPizza, setPrecoTamanhoPizza] = useState('');
  const [itensMontagens, setItensMontagens] = useState({});

  const aumentarQuantidade = useCallback(() => {
    setQuantidade(qtd => qtd + 1);
  }, []);

  const diminuirQuantidade = useCallback(() => {
    setQuantidade(qtd => Math.max(1, qtd - 1));
  }, []);

  const aumentarAdicionalDesabilitado = useMemo(() => {
    return (
      !!produto.qtdMaxAdicional &&
      (adicionais.length >= produto.qtdMaxAdicional ||
        produto.qtdMaxAdicional === 0)
    );
  }, [adicionais.length, produto.qtdMaxAdicional]);

  const aumentarAdicional = useCallback(
    adicional => {
      setAdicionais(state =>
        produce(state, draftState => {
          if (!aumentarAdicionalDesabilitado) {
            draftState.push(adicional);
          }
        }),
      );
    },
    [aumentarAdicionalDesabilitado],
  );

  const diminuirAdicional = useCallback(id => {
    setAdicionais(state =>
      produce(state, draftState => {
        const adicionalIndex = draftState.findIndex(
          adicional => adicional.id === id,
        );

        if (adicionalIndex !== -1) {
          draftState.splice(adicionalIndex, 1);
        }
      }),
    );
  }, []);

  const selecionarTamanho = useCallback(
    index => {
      if (produto.tipoProduto === 'PIZZA') {
        const tamanho = produto.tamanhos[index];
        const qtdTamanho = qtdTamanhos[tamanho.descricao];
        const qtdSabores = +empresa.configuracoes[qtdTamanho];

        if (qtdSabores - 1 < saboresSelecionados.length) {
          const remover = saboresSelecionados.length - (qtdSabores - 1);

          const msg = `Remova ${remover}  ${
            remover > 1 ? 'sabores' : 'sabor'
          } para trocar o tamanho`;
          if (Platform.OS === 'android') {
            ToastAndroid.show(msg, ToastAndroid.SHORT);
          } else {
            //ToastAndroid.show(msg, ToastAndroid.SHORT);
            //AlertIOS.alert(msg);
          }
        } else {
          setTamanhoSelecionado(produto.tamanhos[index]);
          setQuantidadeSabores(qtdSabores);
          setPrecoTamanhoPizza(precoTamanhosPizza[tamanho.descricao]);
        }
      } else {
        setTamanhoSelecionado(produto.tamanhos[index]);
      }
    },
    [
      empresa.configuracoes,
      produto.tamanhos,
      produto.tipoProduto,
      saboresSelecionados.length,
    ],
  );

  const selecionarSabor = useCallback(
    sabor => {
      setSaboresSelecionados(state =>
        produce(state, draftState => {
          if (draftState.length < quantidadeSabores - 1) {
            draftState.push(sabor);
          }
        }),
      );
    },
    [quantidadeSabores],
  );

  const removerSabor = useCallback(index => {
    setSaboresSelecionados(state =>
      produce(state, draftState => {
        draftState.splice(index, 1);
      }),
    );
  }, []);

  const adicionarItemMontagem = useCallback(
    (item, idMontagem, montagemIndex) => {
      setItensMontagens(state =>
        produce(state, draftState => {
          draftState[idMontagem] = draftState[idMontagem] || [];
          const montagem = produto.montagens[montagemIndex];

          const precoMontagem =
            draftState[idMontagem].length < montagem.qtdSemCusto
              ? 0
              : item.precoVenda;

          draftState[idMontagem].push({
            ...item,
            precoMontagem,
            precoMontagemFormatado: formatarValor(precoMontagem),
          });
        }),
      );
    },
    [produto.montagens],
  );

  const removerItemMontagem = useCallback(
    (idItem, idMontagem, montagemIndex) => {
      setItensMontagens(state =>
        produce(state, draftState => {
          const intemIndex = draftState[idMontagem].findIndex(
            item => item.id === idItem,
          );

          if (intemIndex !== -1) {
            draftState[idMontagem].splice(intemIndex, 1);
            const montagem = produto.montagens[montagemIndex];

            draftState[idMontagem] = draftState[idMontagem].map(
              (item, index) => {
                if (index < montagem.qtdSemCusto) {
                  return {
                    ...item,
                    precoMontagem: 0,
                    precoMontagemFormatado: formatarValor(0),
                  };
                }

                return item;
              },
            );
          }
        }),
      );
    },
    [produto.montagens],
  );

  const preco = useMemo(() => {
    if (produto.tipoProduto === '' || produto.tipoProduto === 'PIZZA' || produto?.IsPizza) {
      if (!tamanhoSelecionado.precoVenda) {
        return produto.menorPreco;
      }

      const precos = [
        tamanhoSelecionado.precoVenda,
        ...saboresSelecionados.map(
          sabor => sabor[precoTamanhosPizza[tamanhoSelecionado.descricao]],
        ),
      ];

      const precoMedio =
        precos.reduce((acc, atual) => {
          return acc + atual;
        }, 0) / precos.length;

      return empresa.configuracoes.cobrarValorMaior
        ? Math.max(...precos)
        : precoMedio;
    }

    if (produto.tipoProduto === 'TAMANHO' || produto.tipoProduto === 'PORCAO') {
      return tamanhoSelecionado.precoVenda || produto.menorPreco;
    }

    return produto.precoVenda;
  }, [
    empresa.configuracoes.cobrarValorMaior,
    produto.menorPreco,
    produto.precoVenda,
    produto.tipoProduto,
    saboresSelecionados,
    tamanhoSelecionado.descricao,
    tamanhoSelecionado.precoVenda,
  ]);

  const totalMontagens = useMemo(() => {
    return Object.values(itensMontagens)
      .flat()
      .reduce((acumulado, item) => {
        return acumulado + item.precoMontagem;
      }, 0);
  }, [itensMontagens]);

  const total = useMemo(() => {
    const totalAdicionais = adicionais.reduce((acumulado, adicional) => {
      return acumulado + adicional.precoVenda;
    }, 0);

    return (preco + totalAdicionais + totalMontagens) * quantidade;
  }, [adicionais, preco, quantidade, totalMontagens]);

  const adicionarDesabilitado = useMemo(() => {
    const desabilitado =
      (produto.tipoProduto === 'TAMANHO' ||
        produto.tipoProduto === 'PORCAO' ||
        produto.tipoProduto === 'PIZZA') &&
      !tamanhoSelecionado.descricao;

    if (produto.montagemPrato) {
      return (
        desabilitado ||
        produto.montagens.some(montagem => {
          if (!montagem.obrigatorio) {
            return false;
          }

          if (!itensMontagens[montagem.id]) {
            return true;
          }

          return !itensMontagens[montagem.id].length;
        })
      );
    }

    return desabilitado;
  }, [
    itensMontagens,
    produto.montagemPrato,
    produto.montagens,
    produto.tipoProduto,
    tamanhoSelecionado.descricao,
  ]);

  const enviarProduto = useCallback(
    observacao => {
      const produtoCarrinho = {
        idProduto: produto.id,
        tipoProduto: produto.tipoProduto,
        descricao: produto.descricao,
        observacao,
        preco,
        precoFormatado: formatarValor(preco),
        tamanho: tamanhoSelecionado.descricao,
        quantidade,
        total,
        totalFormatado: formatarValor(total),
        adicionais,
        sabores: saboresSelecionados.map(saborSelecionado => {
          return {
            id: saborSelecionado.id,
            descricao: saborSelecionado.descricao,
            precoVenda: saborSelecionado[precoTamanhoPizza],
            precoVendaFormatado:
              saborSelecionado[`${precoTamanhoPizza}Formatado`],
          };
        }),
        montagens: Object.entries(itensMontagens).map(([idMontagem, itens]) => {
          const montagem = produto.montagens.find(
            mnt => +mnt.id === +idMontagem,
          );
          return {
            idMontagem,
            descricao: montagem.descricao,
            itens,
          };
        }),
      };

      adicionar(produtoCarrinho);
    },
    [
      adicionais,
      adicionar,
      itensMontagens,
      preco,
      precoTamanhoPizza,
      produto.descricao,
      produto.id,
      produto.montagens,
      produto.tipoProduto,
      quantidade,
      saboresSelecionados,
      tamanhoSelecionado.descricao,
      total,
    ],
  );

  useEffect(() => {
    (async () => {
      try {
        const dados = await buscarProduto({ idEmpresa: empresa.id, idProduto });

        const tamanhos = dados.tamanhos.filter(
          tamanho =>
            dados.tipoProduto !== 'PIZZA' ||
            (empresa.configuracoes[usaTamanhos[tamanho.descricao]] &&
              tamanho.precoVenda),
        );

        setProduto({
          ...dados,
          tamanhos,
        });
        setStatus(STATUS.SUCESSO);
      } catch (error) {
        setStatus(STATUS.ERRO);
      }
    })();
  }, [empresa.configuracoes, empresa.id, idProduto]);

  return (
    <ProdutoContext.Provider
      value={{
        produto,
        quantidade,
        aumentarQuantidade,
        diminuirQuantidade,
        status,
        aumentarAdicional,
        diminuirAdicional,
        aumentarAdicionalDesabilitado,
        tamanhoSelecionado,
        selecionarTamanho,
        totalFormatado: formatarValor(total),
        adicionarDesabilitado,
        saboresSelecionados,
        quantidadeSabores,
        selecionarSabor,
        removerSabor,
        precoTamanhoPizza,
        adicionarItemMontagem,
        removerItemMontagem,
        itensMontagens,
        enviarProduto,
      }}
    >
      {children}
    </ProdutoContext.Provider>
  );
};

export const useProduto = () => {
  const context = useContext(ProdutoContext);

  if (!context) {
    throw new Error('ProdutoContext must be used within an ProdutoProvider');
  }

  return context;
};
