import axios from 'axios';

const api = axios.create({
  baseURL: 'https://cardapio.menucheff.com/api',
  // baseURL: 'http://localhost:3333/api',
  timeout: 10000,
  headers: {
    secret: '2348ffc3969e288be2e52c079e44b715',
  },
});

export default api;
