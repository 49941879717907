import api from './api';

export async function criarSessao({
  idModulo,
  nomeCliente,
  tipoModulo,
  vendaSemAutenticacao,
  verCategorias,
  verChamaGarcom,
  orderDescricao,
  utilizaCouvert,
  precoCouvert,
  moduloMesaCobrarCouvertNaAbertura,
  moduloMesaQtdeCouvertNaAbertura,
  moduloCartaoCobrarCouvertNaAbertura,
  moduloCartaoQtdeCouvertNaAbertura,
  vendaBloqueadaCartaoLivre,
  empresa,
}) {
  const res = await api.post('/sessoes', {
    idModulo,
    nomeCliente,
    tipoModulo,
    vendaSemAutenticacao,
    verCategorias,
    verChamaGarcom,
    orderDescricao,
    utilizaCouvert,
    precoCouvert,
    moduloMesaCobrarCouvertNaAbertura,
    moduloMesaQtdeCouvertNaAbertura,
    moduloCartaoCobrarCouvertNaAbertura,
    moduloCartaoQtdeCouvertNaAbertura,
    vendaBloqueadaCartaoLivre,
    empresa,
  });
  return res.data;
}

export async function mostarSessao(id) {
  const res = await api.get(`/sessoes/${id}`);
  return res.data;
}
