import AsyncStorage from '@react-native-async-storage/async-storage';
import { listarPedidos } from '../services/pedidos';

export const resetSession = (minutes, encerrarSessao) => {
  const min = minutes ?? 6;
  let time;
  async function checkAndEndSession() {
    const sessaoArmazenada = JSON.parse(
      await AsyncStorage.getItem('@menucheff-cardapio:sessao'),
    );

    const produtos = await listarPedidos({ idSessao: sessaoArmazenada.id });

    if (!produtos || produtos.length === 0) {
      encerrarSessao();
    } else {
      resetTimer();
    }
  }
  function resetTimer() {
    if (time) {
      clearTimeout(time);
    }
    time = setTimeout(checkAndEndSession, min * 60 * 1000);
  }
  resetTimer();

  return () => {
    clearTimeout(time);
    console.log('Timer da sessão foi limpo.');
  };
};
