import React, { useCallback, useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import erroImg from '../../assets/embarrassed.png';
import Carregando from '../../components/Carregando';
import Erro from '../../components/Erro';
import STATUS from '../../config/status';
import { listarGrupos } from '../../services/grupos';
import Cabecalho from './Cabecalho';
import CategoriaProdutos from './CategoriaProdutos';
import Categorias from './Categorias';
import { Container, ContainerInterno } from './styles';
import { useSessao } from '../../hooks/sessao';

const Cardapio = () => {
  const [grupos, setGrupos] = useState([]);
  const { sessao } = useSessao();
  const [status, setStatus] = useState(STATUS.CARREGANDO);

  const navigation = useNavigation();

  const buscarDados = async () => {
    try {
      setStatus(STATUS.CARREGANDO);
      const gruposDados = await listarGrupos({
        listarProdutos: true,
      });

      setGrupos(gruposDados);
      setStatus(STATUS.SUCESSO);
    } catch (error) {
      setStatus(STATUS.ERRO);
    }
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const buscarCompleto = useCallback(
    grupo => {
      navigation.navigate('BuscarCompleto', {
        grupoId: grupo.id,
        grupoNome: grupo.descricao,
      });
    },
    [navigation],
  );

  const verProduto = useCallback(
    produto => {
      navigation.navigate('Produto', { idProduto: produto.id });
    },
    [navigation],
  );

  if (Platform.OS === 'web') {
    return (
      <ScrollView persistentScrollbar>
        <ContainerInterno
          ListFooterComponent={<View style={{ marginBottom: '40%' }} />}
          persistentScrollbar
          data={grupos}
          keyExtractor={grupo => `${grupo.id}-Grupos-Cardapio`}
          ListHeaderComponent={() => (
            <>
              <Cabecalho />
              {sessao.empresa.configuracoes.verCategorias &&
                <Categorias grupos={grupos} buscarCompleto={buscarCompleto} />
              }
            </>
          )}
          renderItem={({ item }) => (
            <CategoriaProdutos
              persistentScrollbar
              grupo={item}
              verProduto={verProduto}
              buscarCompleto={buscarCompleto}
            />
          )}
        />
      </ScrollView>
    );
  }
  return (
    <Container>
      {status === STATUS.ERRO && (
        <Erro
          imagem={erroImg}
          mensagem="Desculpe! Ocorreu um erro"
          recarregar={buscarDados}
        />
      )}

      {status === STATUS.CARREGANDO && <Carregando />}

      {status === STATUS.SUCESSO && (
        <ContainerInterno
          data={grupos}
          keyExtractor={grupo => `${grupo.id}-Grupos-Cardapio`}
          ListHeaderComponent={() => (
            <>
              <Cabecalho />
              <Categorias grupos={grupos} buscarCompleto={buscarCompleto} />
            </>
          )}
          renderItem={({ item }) => (
            <CategoriaProdutos
              grupo={item}
              verProduto={verProduto}
              buscarCompleto={buscarCompleto}
            />
          )}
        />
      )}
    </Container>
  );
};

export default Cardapio;
