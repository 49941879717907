import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ActivityIndicator, TouchableOpacity } from 'react-native';

import { useNavigation, useRoute } from '@react-navigation/native';

import erroImg from '../../assets/embarrassed.png';
import Container from '../../components/Container';
import Erro from '../../components/Erro';
import {Input} from '../../components/InputPesquisa';
import Produtos from '../../components/Produtos';
import { useTema } from '../../hooks/tema';
import { listarProdutos } from '../../services/produtos';
import {
  ContainerInput,
  BotaoVoltar,
  BotaoVoltarIcone,
  Carregando,
  Resultado,
  Icone
} from './styles';
import { useSessao } from '../../hooks/sessao';


const STATUS = {
  CARREGANDO: 'carregando',
  FINALIZADO: 'finalizado',
  ERRO: 'erro',
};

const BuscarCompleto = () => {
  const { params } = useRoute();
  const { sessao} = useSessao();
  const { grupoNome } = params;
  const { control, handleSubmit } = useForm();

  const [grupoId, setGrupoId] = useState(() => params.grupoId);
  const [produtoNome, setProdutoNome] = useState(() => params.produtoNome);
  const [produtosDados, setProdutosDados] = useState({
    produtos: [],
    total: 0,
    quantidade: 0,
    paginas: 0,
  });

  const [status, setStatus] = useState(() => STATUS.CARREGANDO);
  const [pagina, setPagina] = useState(1);

  const { tema } = useTema();

  const navigation = useNavigation();

  const voltar = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const buscarProduto = async ({ produtoNomeBuscar, grupoIdBuscar, pg }) => {
    try {
      setStatus(STATUS.CARREGANDO);
      const dados = await listarProdutos({
        produtoNome: produtoNomeBuscar,
        grupoId: grupoIdBuscar,
        pagina: pg,
        order: sessao.empresa.configuracoes.orderDescricao === true ? 1 : 0,
      });
      setProdutosDados(dados);
      setStatus(STATUS.FINALIZADO);
    } catch (error) {
      setStatus(STATUS.ERRO);
    }
  };

  const onSubmit = async ({ produtoNomeBuscar }) => {
    setGrupoId(0);
    setPagina(1);
    setProdutoNome(produtoNomeBuscar);
    await buscarProduto({ produtoNomeBuscar });
  };

  const verProduto = useCallback(
    produto => {
      navigation.navigate('Produto', { idProduto: produto.id });
    },
    [navigation],
  );

  const paginacao = useCallback(async () => {
    if (pagina === produtosDados.paginas) {
      return;
    }

    try {
      const dados = await listarProdutos({
        produtoNome,
        grupoId,
        pagina: pagina + 1,
        order: sessao.empresa.configuracoes.orderDescricao === true ? 1 : 0,
      });

      setPagina(pagina + 1);

      setProdutosDados(state => ({
        ...dados,
        produtos: [...state.produtos, ...dados.produtos],
      }));
    } catch (error) {
      setStatus(STATUS.ERRO);
    }
  }, [grupoId, pagina, produtoNome, produtosDados.paginas]);

  useEffect(() => {
    (async () => {
      await buscarProduto({
        produtoNomeBuscar: params.produtoNome,
        grupoIdBuscar: params.grupoId,
      });
    })();
  }, [params]);

  return (
    <Container corStatusBar={tema.cores.primaria}>
      <ContainerInput
        pointerEvents={status !== STATUS.CARREGANDO ? 'auto' : 'none'}
      >
        <BotaoVoltar onPress={voltar}>
          <BotaoVoltarIcone />
        </BotaoVoltar>
        <Controller
          control={control}
          name="produtoNomeBuscar"
          defaultValue={produtoNome || ''}
          render={({ onChange, onBlur, value }) => (
              <Input
                iconName="search"
                placeholder="Buscar"
                returnKeyType="search"
                editable={status !== STATUS.CARREGANDO}
                onBlur={onBlur}
                onChangeText={texto => onChange(texto)}
                value={value}
                onPress={handleSubmit((onSubmit))}
                onSubmitEditing={handleSubmit((onSubmit))}
              />
          )}
        />
      </ContainerInput>

      {status === STATUS.CARREGANDO && (
        <Carregando>
          <ActivityIndicator size={80} color={tema.cores.primaria} />
        </Carregando>
      )}

      {status === STATUS.ERRO && (
        <Erro
          imagem={erroImg}
          mensagem="Desculpe! Não foi possível carregar os produtos"
        />
      )}

      {status === STATUS.FINALIZADO && (
        <>
          {grupoId ? (
            <Resultado>
              {produtosDados.total} produtos na categoria {grupoNome}
            </Resultado>
          ) : (
            <Resultado>
              {produtosDados.total} produtos encontrados{' '}
              {produtoNome && `para "${produtoNome}"`}
            </Resultado>
          )}
          <Produtos
            produtos={produtosDados.produtos}
            paginacao={paginacao}
            total={produtosDados.total}
            chave="BuscarCompleto"
            onPressProduto={verProduto}
          />
        </>
      )}
    </Container>
  );
};

export default BuscarCompleto;
