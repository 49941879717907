import React, { useCallback } from 'react';

import { useNavigation, useRoute } from '@react-navigation/native';

import { useSessao } from '../../hooks/sessao';
import {
  // BotaoVoltar,
  // BotaoVoltarIcone,
  ContainerInput,
} from '../BuscarCompletoPwa/styles';
import { ProdutoProvider } from './hooks/produto';
import ProdutoContainer from './ProdutoContainer';
import { Dimensions } from 'react-native';

// import { Container } from './styles';

const Produto = () => {
  const navigation = useNavigation();


  const voltar = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const {
    params: { idProduto },
  } = useRoute();
  const { empresa } = useSessao();

  return (
    <ProdutoProvider empresa={empresa} idProduto={idProduto}>
      <ContainerInput>
        {/* <BotaoVoltar onPress={voltar}>
          <BotaoVoltarIcone />
        </BotaoVoltar> */}
      </ContainerInput>
      <ProdutoContainer />
    </ProdutoProvider>
  );
};

export default Produto;
