import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import produce from 'immer';

const CarrinhoContext = createContext({});

const ACTIONS = {
  ADICIONAR: 'ADICIONAR',
  REMOVER: 'REMOVER',
  LIMPAR: 'LIMPAR',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.ADICIONAR:
      return [...state, { idCarrinho: Date.now(), ...payload }];
    case ACTIONS.REMOVER:
      return produce(state, draftState => {
        draftState.splice(payload, 1);
      });
    case ACTIONS.LIMPAR:
      return [];
    default:
      return state;
  }
};

export const CarrinhoProvider = ({ children }) => {
  const [carrinho, dispatch] = useReducer(reducer, []);

  const total = useMemo(() => {
    return carrinho.reduce((acumulado, atual) => {
      return acumulado + atual.total;
    }, 0);
  }, [carrinho]);

  const adicionar = useCallback(produto => {
    dispatch({
      type: ACTIONS.ADICIONAR,
      payload: produto,
    });
  }, []);

  const remover = useCallback(carrinhoIndex => {
    dispatch({
      type: ACTIONS.REMOVER,
      payload: carrinhoIndex,
    });
  }, []);

  const limpar = useCallback(() => {
    dispatch({
      type: ACTIONS.LIMPAR,
    });
  }, []);

  return (
    <CarrinhoContext.Provider
      value={{
        carrinho,
        total,
        qtdItens: carrinho.length,
        adicionar,
        remover,
        limpar,
      }}
    >
      {children}
    </CarrinhoContext.Provider>
  );
};

export const useCarrinho = () => {
  const context = useContext(CarrinhoContext);

  if (!context) {
    throw new Error('CarrinhoContext must be used within an CarrinhoProvider');
  }

  return context;
};
