import { lighten, darken } from 'polished';

export default {
  cores: {
    primaria: '#fff',
    primariaEscura: darken(0.1, '#fff'),
    primariaClara: lighten(0.1, '#fff'),
    sucesso: '#28a745',
    erro: '#dc3545',
    atencao: '#ffc107',
    informacao: '#007bff',
    superficie: '#ffffff',
    unitech: '#003366',
    fundo: '#fafafa',
    cinzas: {
      c1: '#eeeeee',
      c2: '#bcbcbc',
      c3: '#848484',
      c4: '#5a5a5a',
    },
    texto: {
      sobrePrimaria: '#ffffff',
      sobreSuperficie: '#424242',
      sobreFundo: '#424242',
    },
  },
  fontes: {
    extraExtraGrande: '30px',
    extraGrande: '24px',
    grande: '20px',
    normal: '16px',
    media: '14px',
    pequena: '12px',
    extraPequena: '10px',
  },
};
