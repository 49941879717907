import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  StatusBar,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Alert,
  Linking,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';

import Container from '../../components/Container';
import ModalPwa from '../../components/ModalPwa';
import { useSessao } from '../../hooks/sessao';
import {
  ContainerInterno,
  Cabecalho,
  BotaoVoltar,
  IconeVoltar,
  Texto,
  Input,
  BotaoNovaSessao,
} from './styles';

const useMount = func => useEffect(() => func(), [func]);

const SessaoCodigo = () => {
  const useInitialURL = () => {
    const [url, setUrl] = useState(null);
    const [processing, setProcessing] = useState(true);

    useMount(() => {
      const getUrlAsync = async () => {
        // Get the deep link used to open the app
        const initialUrl = await Linking.getInitialURL();

        // The setTimeout is just for testing purpose
        setTimeout(() => {
          setUrl(initialUrl);
          setProcessing(false);
        }, 1000);
      };

      getUrlAsync();
    });

    return { url, processing };
  };

  const navigation = useNavigation();
  const { tema } = useSessao();
  const { control, handleSubmit } = useForm();
  const [isVisible, setIsVisible] = React.useState(false);
  const { url: initialUrl } = useInitialURL();

  const codigoCapturado = `${initialUrl}`;
  const codigoSplit = codigoCapturado.split('/');
  const codigoFinal = `${codigoSplit[3]}`;

  function obterCodigo(data) {
    const regex = /^(\d+)(M|C)(\d+)$/i;

    let codigo = '';
    if (Platform.OS === 'web') {
      codigo = codigoFinal.toUpperCase();
    } else {
      codigo = data.codigo.toUpperCase();
    }

    if (regex.test(codigo)) {
      const res = codigo.match(regex);

      const dados = {
        contrato: res[3],
        idModulo: res[1],
        tipoModulo: res[2] === 'M' ? 'MESA' : 'CARTAO',
      };

      navigation.navigate('SessaoNomeCliente', dados);
    } else {
      if (Platform.OS === 'web') {
        setIsVisible(true);
      }

      Alert.alert('Menucheff', 'Código Invalido!');
    }
  }

  return (
    <KeyboardAvoidingView
      style={{
        flex: 1,
      }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      {Platform.OS === 'web' && (
        <ModalPwa
          visivel={isVisible}
          titulo="Código Invalido!"
          onClose={() => setIsVisible(false)}
          swipeDirection={null}
        />
      )}
      <ScrollView
        keyboardShouldPersistTaps="handled"
        scrollable={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Container>
          <StatusBar
            barStyle="dark-content"
            backgroundColor={tema.cores.superficie}
          />

          <Cabecalho>
            <BotaoVoltar onPress={() => navigation.goBack()}>
              <IconeVoltar />
            </BotaoVoltar>
            <Texto>Inserir o Código da Mesa</Texto>
          </Cabecalho>
          <ContainerInterno>
            {Platform.OS !== 'web' && (
              <Controller
                control={control}
                name="codigo"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    autoCompleteType="name"
                    autoFocus
                    textAlign="center"
                    returnKeyType="send"
                    onBlur={onBlur}
                    onChangeText={texto => {
                      onChange(texto);
                    }}
                    value={value}
                    onSubmitEditing={handleSubmit(obterCodigo)}
                  />
                )}
              />
            )}
            {Platform.OS === 'web' && (
              <Controller
                control={control}
                name="codigo"
                defaultValue=""
                render={({ onChange, onBlur }) => (
                  <Input
                    autoCompleteType="name"
                    autoFocus
                    textAlign="center"
                    returnKeyType="send"
                    onBlur={onBlur}
                    onChangeText={texto => {
                      onChange(texto);
                    }}
                    value={`${codigoFinal}` || ''}
                    onSubmitEditing={handleSubmit(obterCodigo)}
                  />
                )}
              />
            )}
            <BotaoNovaSessao onPress={handleSubmit(obterCodigo)}>
              Entrar
            </BotaoNovaSessao>
          </ContainerInterno>
        </Container>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default SessaoCodigo;
