import React, { memo } from 'react';

import ProdutoSemImagem from '../../assets/sem-imagem.png';
import {
  Produto,
  ProdutoImagem,
  ProdutoInfo,
  ProdutoDescricao,
  ProdutoComposicao,
  PrecoAPatirContainer,
  PrecoAPatir,
  ProdutoPreco,
} from './styles';

const ProdutoCard = ({ onPress, produto, visivel = true }) => {
  return (
    <Produto onPress={onPress} visivel={visivel}>
      <ProdutoImagem
        resizeMode="cover"
        src={produto.imagem}
        fallbackImg={ProdutoSemImagem}
      />
      <ProdutoInfo>
        <ProdutoDescricao>{produto.descricao}</ProdutoDescricao>

        {!!produto.composicao && (
          <ProdutoComposicao>{produto.composicao}</ProdutoComposicao>
        )}

        {produto.menorPreco ? (
          <PrecoAPatirContainer>
            <PrecoAPatir>A partir de</PrecoAPatir>
            <ProdutoPreco>{produto.menorPrecoFormatado}</ProdutoPreco>
          </PrecoAPatirContainer>
        ) : (
          <ProdutoPreco>{produto.precoVendaFormatado}</ProdutoPreco>
        )}
      </ProdutoInfo>
    </Produto>
  );
};

export default memo(ProdutoCard);
