import React, { memo } from 'react';
import { TouchableOpacity } from 'react-native';

const BotaoBase = ({
  desabilitado = false,
  style,
  containerStyle,
  children,
  backgroundColor,
  ...rest
}) => {
  return (
    <TouchableOpacity style={style} {...rest} disabled={desabilitado}>
      {children}
    </TouchableOpacity>
  );
};

export default memo(BotaoBase);
