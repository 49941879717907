import React, { useCallback, useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import carrinhoImagem from '../../assets/carrinho-vazio.png';
import erroImg from '../../assets/embarrassed.png';
import Container from '../../components/Container';
import Erro from '../../components/Erro';
import ModalPwaEnviado from '../../components/ModalPwaEnviado';
import STATUS from '../../config/status';
import { useSessao } from '../../hooks/sessao';
import { listarPedidos } from '../../services/pedidos';
import formatarValor from '../../utils/formatarValor';
import {
  BotaoEncerrar,
  Produtos,
  TotalTexto,
  BotaoEncerrarContaier,
  TotalValor,
  PedidosVazioContainer,
  PedidosVazioImagem,
  PedidosVazioTitulo,
  PedidosVazioMensagem,
  Produto,
  ProdutoDescricao,
  ProdutoPreco,
  ProdutoQuantidade,
  PedidosRealizados,
  PedidosRealizadosTitle,
} from './styles';
import Carregando from '../../components/Carregando';

const Conta = () => {
  const { encerrarSessao, sessao, empresa } = useSessao();
  const [total, setTotal] = useState(0);
  const [servico, setServico] = useState(0);
  const [couvert, setCouvert] = useState(0);
  const [produtos, setProdutos] = useState([]);
  const [status, setStatus] = useState(STATUS.CARREGANDO);
  const [isVisibleEnviado, setIsVisibleEnviado] = useState(false);
  const focado = useIsFocused();
  const { cardapioVisualizacao } = empresa.configuracoes;

  const renderProduto = useCallback(
    ({ item }) => (
      <Produto>
        <ProdutoQuantidade>{item.quantidade} x </ProdutoQuantidade>
        <ProdutoDescricao>{item.descricao}</ProdutoDescricao>
        <ProdutoPreco>{item.totalFormatado}</ProdutoPreco>
      </Produto>
    ),
    [],
  );

  useEffect(() => {
    (async () => {
      if (focado) {
        setStatus(STATUS.CARREGANDO);
        const timer = window.setInterval(async () => {
          try {
            let itensPedido = await listarPedidos({ idSessao: sessao.id });

            itensPedido = itensPedido.filter((item) => item.idPedidoProduto !== undefined)

            const totalPedidos = itensPedido.reduce(
              (valorTotal, item) => valorTotal + item.total,
              0,
            );

            setTotal(totalPedidos);
            setServico(sessao.cobrarTaxaServico ? sessao.txServico * totalPedidos / 100 : 0);

            if (sessao.utilizaCouvert && sessao.moduloCartaoCobrarCouvertNaAbertura && sessao.tipoModulo === 'CARTÃO') {
              setCouvert(sessao.precoCouvert * sessao.moduloCartaoQtdeCouvertNaAbertura);
            } else if (sessao.utilizaCouvert && sessao.moduloMesaCobrarCouvertNaAbertura && sessao.tipoModulo === 'MESA') {
              setCouvert(sessao.precoCouvert * sessao.moduloMesaQtdeCouvertNaAbertura);
            } else if (sessao.utilizaCouvert && !sessao.moduloMesaCobrarCouvertNaAbertura && sessao.tipoModulo === 'MESA') {
              setCouvert(sessao.valorCouvert);
            } else if (sessao.utilizaCouvert && !sessao.moduloCartaoCobrarCouvertNaAbertura && sessao.tipoModulo === 'CARTÃO') {
              setCouvert(sessao.valorCouvert);
            } else {
              setCouvert(0);
            }

            setProdutos(itensPedido);
            setStatus(STATUS.SUCESSO);
          } catch (error) {
            setStatus(STATUS.ERRO);
          }
        }, 5000);
        return () => {
          window.clearInterval(timer);
        };
      }

    })();
  }, [sessao.id, focado]);

  if (status === STATUS.CARREGANDO && focado) {
    return (<Carregando />)
  }

  return (
    <Container>
      <ModalPwaEnviado
        visivel={isVisibleEnviado}
        titulo="Pedido enviado com sucesso"
        onClose={() => setIsVisibleEnviado(false)}
        swipeDirection={null}
      />
      {status === STATUS.ERRO && focado && (
        <>
          <Erro imagem={erroImg} mensagem="Desculpe! Ocorreu um erro" />
          <BotaoEncerrarContaier>
            {!cardapioVisualizacao && sessao.sessaoCardapioInfinita === 0 && (
              <BotaoEncerrar onPress={encerrarSessao}>
                Encerrar Pedido
              </BotaoEncerrar>
            )}
          </BotaoEncerrarContaier>
        </>
      )}

      <>
        {produtos.length ? (
          <PedidosRealizados>
            <PedidosRealizadosTitle>Produtos</PedidosRealizadosTitle>
            <Produtos
              data={produtos}
              keyExtractor={item => `${item.idPedidoProduto}-Produto-Conta`}
              renderItem={renderProduto}
            />
          </PedidosRealizados>
        ) : (
          <PedidosVazioContainer>
            <PedidosVazioImagem source={carrinhoImagem} resizeMode="cover" />
            <PedidosVazioTitulo>Sem pedidos</PedidosVazioTitulo>
            <PedidosVazioMensagem>
              Nenhum pedido feito ainda.
            </PedidosVazioMensagem>
          </PedidosVazioContainer>
        )}
        <BotaoEncerrarContaier>
          <TotalTexto>
            Taxa de Serviço: <TotalValor>{formatarValor(servico)}</TotalValor>{' '}
          </TotalTexto>
          <TotalTexto>
            Couvert: <TotalValor>{formatarValor(couvert)}</TotalValor>{' '}
          </TotalTexto>
          <TotalTexto>
            Total: <TotalValor>{formatarValor(total + servico + couvert)}</TotalValor>{' '}
          </TotalTexto>
          {!cardapioVisualizacao && sessao.sessaoCardapioInfinita === 0 && (
            <BotaoEncerrar onPress={encerrarSessao}>
              Encerrar Pedido
            </BotaoEncerrar>
          )}
        </BotaoEncerrarContaier>
      </>
    </Container>
  );
};

export default Conta;
