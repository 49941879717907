import React from 'react';
import { ActivityIndicator } from 'react-native';

import { Container, Texto } from './styles';

const Botao = ({ children, style, textoStyle, carregando, ...rest }) => {
  return (
    <Container style={style} {...rest}>
      {carregando ? (
        <ActivityIndicator size="small" color="#fff" />
      ) : (
        <>
          {typeof children === 'string' ? (
            <Texto style={[textoStyle]}>{children}</Texto>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </Container>
  );
};

export default Botao;
