import React from 'react';
import { Linking } from 'react-native';
import { TouchableHighlight } from 'react-native-gesture-handler';

import logoEnderecoImg from '../../assets/endereco.png';
import logoFaceImg from '../../assets/facebook.png';
import logoInstaImg from '../../assets/instagram.png';
import logoUnitechImg from '../../assets/logo-unitech.png';
import logoWhatsappImg from '../../assets/whatsapp.png';
import Container from '../../components/Container';
import {
  LogoUniTechContainer,
  LogoUniTech,
  RedeSocialContainer,
  RedeSocialLogo,
  RedeSocialDescricao,
  BotaoContato,
  DesenvolvidoPor,
} from './styles';

const Desenvolvedor = () => {
  function abrirWhatsapp() {
    Linking.openURL(`whatsapp://send?phone=${5563992269239}`);
  }

  return (
    <Container>
      <LogoUniTechContainer>
        <DesenvolvidoPor>Desenvolvido por:</DesenvolvidoPor>
        <LogoUniTech source={logoUnitechImg} resizeMode="contain" />
      </LogoUniTechContainer>
      <TouchableHighlight
        onClick={() =>
          Linking.openURL(`whatsapp://send?phone=${5563992269239}`)
        }
      >
        <RedeSocialContainer>
          <RedeSocialLogo
            source={logoWhatsappImg}
            onClick={() =>
              Linking.openURL(`whatsapp://send?phone=${5563992269239}`)
            }
          />

          <RedeSocialDescricao
            onPress={() =>
              Linking.openURL(`whatsapp://send?phone=${5563992269239}`)
            }
          >
            (63) 99226-9239
          </RedeSocialDescricao>
        </RedeSocialContainer>
      </TouchableHighlight>
      <RedeSocialContainer>
        <RedeSocialLogo source={logoInstaImg} />
        <RedeSocialDescricao>@unitech_sistemas</RedeSocialDescricao>
      </RedeSocialContainer>
      <RedeSocialContainer>
        <RedeSocialLogo source={logoFaceImg} />
        <RedeSocialDescricao>facebook.com/unitechsistemas</RedeSocialDescricao>
      </RedeSocialContainer>
      <RedeSocialContainer style={{ alignItems: 'flex-start' }}>
        <RedeSocialLogo source={logoEnderecoImg} />
        <RedeSocialDescricao>
          Av. Tocantins, 20 - Q 122 - Taquaralto, Palmas - TO, 77066-044
        </RedeSocialDescricao>
      </RedeSocialContainer>

      <BotaoContato onPress={abrirWhatsapp}>Entre em contato</BotaoContato>
    </Container>
  );
};

export default Desenvolvedor;
