import api from './api';

export async function criarPedido({ idSessao, produtos, localEntrega }) {
  const res = await api.post(`pedidos`, {
    idSessao,
    produtos,
    localEntrega,
  });
  return res.data;
}

export async function listarPedidos({ idSessao }) {
  const res = await api.get(`pedidos/${idSessao}`);
  return res.data;
}
