import 'intl';
import 'intl/locale-data/jsonp/pt-BR';

import React from 'react';

import { NavigationContainer } from '@react-navigation/native';

import { CarrinhoProvider } from './src/hooks/carrinho';
import { SessaoProvider } from './src/hooks/sessao';
import { TemaProvider } from './src/hooks/tema';
import Routes from './src/routes';

const App = () => {
  return (
    <NavigationContainer>
      <TemaProvider>
        <CarrinhoProvider>
          <SessaoProvider>
            <Routes />
          </SessaoProvider>
        </CarrinhoProvider>
      </TemaProvider>
    </NavigationContainer>
  );
};

export default App;
