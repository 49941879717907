import React, { memo } from 'react';

import { useSessao } from '../../../hooks/sessao';
import BotaoQuantidade from '../BotaoQuantidade';
import {
  Container,
  BotaoAdicionar,
  BotaoAdicionarTexto,
  BotaoAdicionarValor,
} from './styles';

const Rodape = ({
  aumentarQuantidade,
  diminuirQuantidade,
  totalFormatado,
  adicionarDesabilitado,
  adicionarProduto,
}) => {
  const { empresa } = useSessao();
  const { cardapioVisualizacao } = empresa.configuracoes;

  return (
    <Container>
      <BotaoQuantidade
        qtdInicial={1}
        aumentar={aumentarQuantidade}
        diminuir={diminuirQuantidade}
      />
      <BotaoAdicionar
        desabilitado={adicionarDesabilitado || cardapioVisualizacao}
        onPress={adicionarProduto}
      >
        <BotaoAdicionarTexto>Adicionar</BotaoAdicionarTexto>
        <BotaoAdicionarValor>{totalFormatado}</BotaoAdicionarValor>
      </BotaoAdicionar>
    </Container>
  );
};

export default memo(Rodape);
